.c-property-details-bar {
    height: 75px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 4px 70px -6px rgba(154, 154, 154, 0.4);
    z-index: 10;

    .property-bar-fixed & {
        position: fixed;
        top: 0;
    }

    @include mq(large) {
        height: 150px;

        .property-bar-fixed & {
            position: relative;
        }
    }
}

.c-property-details-bar-fixed {
    .property-bar-fixed & {
        position: fixed;
        top: 0;
    }
}

.c-property-details-bar .c-button {
    float: right;
    margin: 14px 0 16px 0;
}

.c-property-details-bar__name {
    @extend .c-property-summary__name;
    padding: 22px 0;
    border: none;

    @include mq(large) {
        border-bottom: 1px solid #b0adad;
        margin-bottom: 0;
    }
}

.c-property-details-bar__bathrooms {
    @extend .c-property-summary__bathrooms;
}

.c-property-details-bar__bedrooms {
    @extend .c-property-summary__bedrooms;
}

.c-property-details-bar__number {
    @extend .c-property-summary__number;
}

.c-property-details-bar__price {
    float: right;
    background: $color-black;
    height: 100%;
    padding: 23px 25px 20px 25px;
    margin: 0 20px;

    @include mq(large) {
        float: left;
        margin: 0;
        padding: 27px 25px 24px;
    }
}

.c-property-details-bar__price span:first-child {
    font-size: 1.5rem;
    color: #fff;
    display: inline-block;

    @include mq(large) {
        font-size: 1rem;
    }
}

// .c-property-details-bar__price span:last-child {
//  font-size: 0.875rem;
//  color: $color-grey-light;
//  display: inline-block;
//  margin-left: 15px;
//  position: relative;
//  top: -2px;

//  @include mq(large) {
//      font-size: 0.75rem;
//  }
// }

.c-property-details-bar__rooms {
    float: right;
    padding: 28px 0 15px 0;
    margin: 0 30px 0 40px;
    position: relative;

    &:before {
        content: '';
        width: 1px;
        height: 35px;
        position: absolute;
        left: -40px;
        top: 50%;
        margin-top: -12px;
        background: #b0adad;
    }

    @include mq(large) {
        float: left;
        margin: 0 20px;

        &:before {
            display: none;
        }
    }
}

.c-home-eval {
   position: fixed;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
   height: 100%;
   width: 100%;
   z-index: 9999;
   background-color: black;
   background-image: url('../images/c-home-eval-bg.jpg');
   background-size: cover;
   background-repeat: no-repeat;

   @include mq(large) {
      //background: #fcfcfc;
      overflow: scroll;
   }
}

.c-home-eval__search {
   width: calc(100% - 40px);
   height: 100%;
   max-width: 680px;
   margin: 0 auto;
   text-align: center;
   display: flex;
   align-items: center;

   @include mq(medium) {
      height: auto;
      padding: 40px 20px;
   }

   @include mq(small) {
      align-items: initial;
   }

   h2 {
      color: white;
      font-size: 2.2rem;

      @include mq(medium) {
         font-size: 1.8rem;
      }
   }

   strong {
      text-transform: uppercase;
      display: block;
      font-size: 2.25rem;
      margin-bottom: -0.2em;

      @include mq(medium) {
         font-size: 1.8rem;
         margin-bottom: initial;
      }
   }

   span {
      color: white;
   }

   p {
      color: white;
      font-size: 2rem;
      margin-top: 2em;

      @include mq(medium) {
         font-size: 1.8rem;
      }
   }

   .c-home-eval__error {
      color: #e85747;
      font-size: 1.2rem;
      opacity: 0;
      pointer-events: none;
      transform: scale(0.9);
      transition: all 500ms cubic-bezier(0.160, 0.725, 0.255, 1.650);

      &.visible {
         opacity: 1;
         transform: scale(1);
      }
   }
}

.c-home-eval__form {
   position: relative;

   input[type="text"] {
      padding: 15px 25px;
      border-radius: 100px;
      display: block;
      width: 100%;
   }
}

.c-home-eval__submit {
   background: $color-black;
   color: white;
   position: absolute;
   top: 4px;
   right: 6px;
   height: 42px;
   width: 42px;
   border-radius: 42px;
   border: none;
   cursor: pointer;
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   align-items: center;

   i {
      transition: all 300ms ease;
      transform: scale(1);
      transform-origin: 50% 50%;
      color: $color-grey-scorpion;
      font-size: 14px;
   }

   &:hover {

      i {
         transform: scale(1.1);
         color: white;
      }
   }
}

.c-home-eval__results {
   height: 100%;
   display: flex;
   background: #fcfcfc;

   @include mq(large) {
      height: auto;
      flex-direction: column;
      text-align: center;
   }

   h2, h3 {
      font-size: 2rem;

      @include mq(medium) {
         font-size: 1.4rem;
      }
   }

   h2 {
      color: $color-grey-scorpion;
   }

   h3 {
      margin-top: 0px;
   }

   small {
      font-size: 0.8rem;
      color: $color-grey-medium;
      line-height: 1.4em;
      margin-top: 60px;

      strong {
         display: block;
      }
   }

   button {
      float: right;
      margin-right: 20px;

      @include mq(large) {
         float: none;
         display: inline-block;
      }
   }

   .wpcf7-mail-sent-ok {
      background: $color-grey-scorpion;
      color: white;
      padding: 8px 20px;
      border-radius: 50px;
      border: none;
   }

   .c-home-eval__search__left {
      height: 100%;
      width: 50%;
      position: relative;

      @include mq(large) {
         height: 400px;
         width: 100%;
         overflow: hidden;

         &:after {
            content: "";
            display: block;
            background: linear-gradient(rgba(255,255,255,0), #fcfcfc);
            height: 200px;
            width: 100%;
            bottom: 0px;
            left: 0px;
            position: absolute;
         }
      }
   }

   .c-home-eval__search__right {
      height: 100%;
      width: 50%;
      overflow-y: auto;
      padding: 10vh 80px 10vh 60px;
      display: flex;
      flex-direction: column;

      @include mq(large) {
         height: auto;
         width: 100%;
         padding: 40px 30px;
      }
   }
}

.c-home-eval__map {
   height: 100%;
   flex-basis: 50%;
   background: white;

   @include mq(large) {
      height: 500px;
   }
}

.pac-container {
   z-index: 99999;
}

.c-home-eval__form ::-webkit-input-placeholder { /* WebKit browsers */
    color: white;
}
.c-home-eval__form :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: white;
}
.c-home-eval__form ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: white;
}
.c-home-eval__form :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: white;
}
.c-select {
   position: relative;
   display: inline-block;

   select {
      appearance: none;
      color: $color-grey-outer-space;
      border: 1px solid $color-grey-outer-space;
      background: none;
      border-radius: 0;
      padding: 8px 25px 6px 12px;
      font-family: $font-poppins;
      font-size: 11px;
      font-weight: 600;
      line-height: 1.1;
      text-transform: uppercase;
      transition: 0.25s ease all;

      .light-theme-select & {
         color: #fff;
         border: 1px solid #fff;
      }

      &:hover,
      &:focus {
         background: #f3f3f3;

         .light-theme-select & {
            background: #292929;
         }
      }

      &:active + .arrow {
         transform: rotate(180deg);
      }
   }

   .arrow {
      display: inline-block;
      width: 9px;
      height: 5px;
      background: url('../images/new/arrow-down-small--dark.png');
      background-size: 100%;
      margin-left: 10px;
      position: absolute;
      opacity: 0.7;
      top: 50%;
      right: 10px;
      margin-top: -2px;
      transition: 0.25s linear opacity;

      .light-theme-select & {
         background-image: url('../images/new/arrow-down-small--light.png');
      }
   }
}

.c-select__wrapper {
   float: right;
   margin-bottom: 30px;
   margin-right: gutter();

   @include mq(small) {
      float: none;
      margin: 0 auto 30px auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .c-select {
         margin-left: 5px;
      }
   }
}

.c-select__label {
   font-family: $font-poppins;
   font-size: 11px;
   color: $color-grey-dim;
   letter-spacing: 1px;
   text-transform: uppercase;
   font-weight: 600;

   &:after {
      content: ' - ';
   }
}

.c-select__label-number {
   font-family: $font-poppins;
   font-size: 11px;
   color: $color-grey-scorpion;
   letter-spacing: 1px;
   text-transform: uppercase;
   font-weight: 600;
}

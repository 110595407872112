.c-property-details {
	overflow: hidden;
	padding-bottom: 60px;
}

.c-property-details h2 {
	color: $color-black;
	font-size: 2rem;
	font-weight: 400;
	margin-top: 50px;
    font-family: $font-poppins;
    text-transform: none;
	&:first-of-type {
		margin-top: 0;
	}

	@include mq(medium) {
		font-size: 2rem;
	}
}

.c-property-details p {
	font-size: 0.9375rem;
}

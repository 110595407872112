.c-page-title {
   font-size: 2.8125rem;
   font-weight: 400;
   color: $color-black;
   margin: 40px 0 60px 0;

   span {
      color: $color-grey-scorpion;
   }

   @include mq(medium) {
      font-size: 2rem;
      margin: 40px 0 30px 0;
   }
}
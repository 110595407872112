.c-calculator {
    width: 300px;
    float: left;

    @include mq(large) {
        float: none;
    }
}

.c-calculator__half {
    width: 47%;
    margin: 0 3%;
    float: left;

    &:nth-child(even) {
        margin-right: 0;
    }

    &:nth-child(odd) {
        margin-left: 0;
    }
}

.c-calculator h3 {
    font-size: 1.25rem;
    font-weight: 700;
    color: $color-black;
}


.c-calculator input {
    width: 100%;
    font-size: 17px;
    color: #868686;
    padding: 13px 55px 13px 15px;
    transition: all 200ms ease;

    &.has-error {
        border-color: red;
    }
}

.c-calculator button {
    border: none;
    margin-top: 30px;
    box-shadow: none;
}

.c-calculator label,
.c-calculator__label {
    display: block;
    color: $color-grey-light;
    font-size: 0.8125rem;
    margin: 15px 0 10px 0;
}

.c-calculator__icon {
    position: relative;

    &:after {
        content: '';
        width: 45px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: $color-grey-outer-space;
        text-align: center;
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 48px;
        color: white;
        font-family: $font-poppins;
    }

    &.i-cash:after {
        content: '$';
    }

    &.i-percent:after {
        content: '%';
    }

    &.i-clock:after {
        content: '\f073';
        font-family: "FontAwesome";
    }
}

.c-calculator__acompte {
    font-size: 17px;
    line-height: 48px;
    color: #868686;
    margin-left: 15px;
    display: block;

    span {
        color: $color-grey-scorpion;
    }
}

.c-calculator-results {
    width: 300px;
    float: left;
    margin-left: 80px;

    @include mq(large) {
        float: none;
        margin-left: 0;
        margin-top: 30px;
    }
}

.c-calculator-results button {
    box-shadow: none;
    margin-top: 20px;
}

.c-calculator-results h3 {
    font-size: 1.25rem;
    font-weight: 700;
    color: $color-black;
}

.c-calculator__result {
    display: block;
    color: $color-black;
    margin: 20px 0;
    font-size: 2.5rem;
    font-family: $font-poppins;

    span {
        color: $color-grey-scorpion;
    }
}



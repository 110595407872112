.c-fake-checkbox__container {
   position: relative;
   clear: both;
   margin-bottom: 5px;

}

.c-fake-checkbox__container:last-of-type {
   margin-bottom: 0px;
}

.c-fake-checkbox {
   height: 20px;
   width: 20px;
   border: 1px solid $color-grey-light;
   display: inline-block;
   border-radius: 5px;
   position: relative;
   transition: all 300ms ease;
   overflow: hidden;
   background: white;

   &:after {
      content: "\f00c";
      display: block;
      color: white;
      font-family: 'fontAwesome';
      position: absolute;
      font-size: 14px;
       left: 2px;
       top: 20px;
       opacity: 0;
       transition: all 300ms ease;
   }
}

.c-fake-checkbox__input:checked + .c-fake-checkbox {
   background: $color-grey-scorpion;
   border-color: $color-grey-scorpion;
   &:after {
      top: 0px;
      opacity: 1;
   }
}

.c-fake-checkbox__input {
   display: none;
}

.c-fake-checkbox__label {
   width: calc(100% - 30px);
    float: right;
    position: relative;
    top: -1px;
    color: $color-grey-light;
    font-size: 13px;
    line-height: 18px;
}
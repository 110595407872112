.c-property-summary {
   @include span(6);
   float: left; 
   min-height: 380px;
   background-size: cover;
   background-position: center calc(50% - 37px);
   display: block;
   position: relative;
   float: left;
   margin: 0 9px;
   margin-bottom: gutter(6);  
   position: relative;
   top: 0;
   box-shadow: 0px 0px 40px 2px rgba(25,23,25,0.15);
   transition: 0.25s linear opacity, 0.15s linear top, 0.15s linear box-shadow;
   overflow: hidden;

   &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: url('../images/c-gradient.png');
      background-size: cover;
      opacity: 0;
      transform: translate(0, 50px);
      transition: 0.25s linear all;
   }

   &:nth-child(odd) {
      margin-left: 0;
   }

   &:nth-child(even) {
      margin-right: 0;
   }

   &:hover,
   &:focus {
      top: -5px;
      //box-shadow: 0px 0px 40px 2px rgba(25,23,25,0);
   
      &:after {
         opacity: 1;
         transform: translate(0, 0);
      }

      .c-property-summary__bar:after {
         background: $color-black;
      }

      .c-property-summary__button {
         background: $color-black;

         svg {
            fill: $color-grey-scorpion;
            stroke: $color-grey-scorpion;
         }
      }
   }

   @include mq(medium) {
      min-height: 330px;
      width: 100%;
      margin: 0 0 40px 0;

      &:nth-child(odd),
      &:nth-child(even) {
         margin: 0 0 40px 0;
      }
   }

   @include mq(small) {
      margin-left: 15px;
      margin-right: 15px;
   }
}

// Different styling when in a carousel
.c-property-summary.in-carousel {
   pointer-events: none;
   opacity: 0.3;
   margin: 0 9px; 

   &:last-of-type {
      margin-right: 9px;
   }

   &:first-of-type {
      margin-left: 9px;
   }

   &.slick-active {
      opacity: 1;
      pointer-events: auto;
   }

   @include mq(large) {
      float: left;
      margin: 0 9px;
      display: inline-block; 

      &:first-of-type,
      &:last-of-type {
         margin: 0 9px;
      }
   }

   @include mq(small) {
      min-height: 330px;
   }
}

.c-property-summary__price {
   position: absolute;
   left: 0;
   top: 0;
   font-size: 1.5rem;
   font-weight: 400;
   background: $color-black;
   padding: 7px 15px;
   color: #fff;
   z-index: 5;

   span {
      color: $color-grey-light;
      font-size: 0.875rem;
      display: inline-block;
      vertical-align: middle;
   }
}

.c-property-summary__bar {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   background: #fff;
   padding: 22px 110px 22px 28px;
   display: flex;
   align-items: center;
   z-index: 5;
   flex-wrap: wrap;
}

.c-property-summary__button {
   content: '';
   font-size: 1.5rem;
   color: $color-black;
   width: 80px;
   height: 100%;
   padding: 0;
   display: inline-block;
   position: absolute;
   right: 0;
   top: 0;
   transition: 0.15s linear all;
   border: none;
   background: $color-grey-scorpion;

   svg {
      width: 40px;
      height: 40px;
      fill: #fff;
      stroke: #fff;
      transition: 0.15s linear all;
   }
}

.c-property-summary__name {
   font-size: 0.8235rem;
   line-height: 1rem;
   color: $color-grey-light;
   padding-right: 20px;
   margin-right: 20px;
   border-right: 1px solid #b0adad;
   float: left;

   span {
      font-size: 1.0625rem;
      line-height: 1rem;
      font-weight: 700;
      color: $color-grey-scorpion;
      display: block;
   }

   @include mq(large) {
      width: 100%;
      margin: 0 0 10px 0;
      padding: 0 0 10px 0;
      border-right: none;
      border-bottom: 1px solid #b0adad;
   }
}

.c-property-summary__bathrooms,
.c-property-summary__bedrooms {
   font-size: 20px;
   margin-right: 10px;
   color: $color-grey-scorpion;
   vertical-align: bottom;
}

.c-property-summary__number {
   font-size: 1rem;
   color: $color-grey-light;
   margin-right: 20px;

   &:last-of-type {
      margin-right: 0;
   }
}

.c-search-bar {
   width: 800px;
   float: left;
   position: relative;

   @include mq(large) {
      width: 100%;
   }
}

.c-search-bar input {
   width: 100%;
   border-color: $color-grey-scorpion;
   border-radius: 50px;
   padding: 20px 26px;
}

.c-search-bar__submit {
   border: none;
   background: $color-black;
   width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;

   svg {
      width: 25px;
      height: 25px;
      position: relative;
      top: 2px;
      fill: $color-grey-scorpion;
      transition: 0.15s linear all;
   }

   &:hover,
   &:focus {
      svg {
         fill: #fff;
      }
   }
}

.cities_list_dropdown {
  padding: 0;
    list-style-type: none;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 50;

    li {
      background-color: #e6e4e4;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }

    &--selected {
      display: none;
      padding: 0;
      list-style-type: none;
      margin: 0;
      margin-bottom: 25px;

      li {
        display: inline-block;
        padding: 5px 30px 5px 10px;
        background-color: #00aeef;
        color: #fff;
        border-radius: 16px;
        margin-right: 15px;
        position: relative;

        &::after {
          content: "\f00d";
          font-family: "FontAwesome";
          color: #fff;
          position: absolute;
          display: block;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          font-weight: 100;
        }
      }
    }
}
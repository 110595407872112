.c-market-news {
	background: $color-grey-white;
	overflow: hidden;
	padding: 30px 0 100px 0;
	position: relative;

	.slick-list {
		overflow: visible;
	}

	@include mq(medium) {
		padding: 10px 0 60px 0;
	}
}

.c-market-news__arrow {
	width: 300px;
	height: 140px;
	border-radius: 500px;
	background-size: 18px 31px;
	background-repeat: no-repeat;
	background-color: $color-black;
	position: absolute;
	top: 48%;
	cursor: pointer;
	border: 0;
	transform: translate(0, -50%);
	z-index: 99999;
	box-shadow: 0px 0px 40px 2px rgba(25,23,25,0.3);
	transition: 0.15s linear all;

	&.animation-is-over.left {
		animation: none;
		left: -205px;
		background-image: url('../images/arrow-left--big--white.png');

		span {
			transform: scale(0);
			transition: all 300ms ease;
		}

		&:hover {
			left: -115px !important;
			background-position: center;

			span {
				animation: none;
				transform: scale(1);
			}
		}
	}

	&.animation-is-over.right {
		animation: none;
		right: -205px;
		background-image: url('../images/arrow-right--big--white.png');

		span {
			transform: scale(0);
			transition: all 300ms ease;
		}

		&:hover {
			right: -115px !important;
			background-position: center;

			span {
				animation: none;
				transform: scale(1);
			}
		}
	}

	&.left {
		left: -115px;
		background-image: none;
		background-position: 82% 50%;
		animation: arrowLeftOut 250ms 3000ms ease forwards;

		span{
			right: 15px;
		}
	}

	&.right {
		right: -115px;
		background-image: none;
		background-position: 18% 50%;
		animation: arrowRightOut 250ms 3000ms ease forwards;


		span{
			left: 15px;
		}
	}

	&:hover {
		background-color: $color-grey-lighter;

	}

	span{
		width: 110px;
		height: 110px;
		position: absolute;
		top: 15px;

		background-size: cover;
		background-position: center;
		border: solid 5px white;
		border-radius: 50%;
		opacity: 1;
		transform: scale(1);
		transition: 0.3s;
		animation: thumbOut 250ms 2900ms ease forwards;

		@include mq('medium') {
			display: none;
		}
	}

	@include mq(medium) {
		width: 100px;
		height: 100px;

		&.left {
			left: -50px;

			&:hover {
				left: -50px;
			}
		}

		&.right {
			right: -50px;

			&:hover {
				right: -50px;
			}
		}
	}
}

.c-market-news .c-button--black {
	margin: 80px 0 0 0;
}

.pre-header {
	color: white;
    text-align: center;
    padding: 5px;
}

@keyframes arrowLeftOut {

	0% {
		left: -115px;
		background-image: none;
	}

	100% {
		left: -205px;
		background-image: url('../images/arrow-left--big--white.png');
	}
}

@keyframes arrowRightOut {

	0% {
		right: -115px;
		background-image: none;
	}

	100% {
		right: -205px;
		background-image: url('../images/arrow-left--big--white.png');
	}
}

@keyframes thumbOut {

	0% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(0);
	}
}

.c-tab-slider-arrows {
	position: absolute;
	background-color: $color-black;
	z-index: 9999;
	border: 0;
	width: 75px;
	height: 75px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 10px;
	margin: auto 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
	transition: all 300ms ease;

	&:hover {
		background-color: $color-grey-lighter;
	}

	&.left {
		background-image: url('../images/arrow-left--big--white.png');
		border-radius: 0 50px 50px 0;
	}

	&.right {
		background-image: url('../images/arrow-right--big--white.png');
		border-radius: 50px 0 0 50px;
	}
}
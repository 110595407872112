.c-formulaire-rendez-vous {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;

    &.active {
	    opacity: 1;
	    visibility: visible;

	    @include mq(medium) {
			padding: 30px;
		}

		@include mq(small) {
			padding: 0;
		}
    }

    .wrapper {
    	max-width: 750px;
    	width: 100%;
 		background: $color-grey-white;
 		padding: 20px;

 		.left-align {
 			margin: 0 0 0 10px;
 			font-size: 20px;
 			display: block;
 		}

        form {
            padding: 15px 25px;
            position: relative;

            input[type=email],
            input[type=number],
            input[type=tel],
            input[type=text],
            textarea {
                background: #e3e3e3;
                color: #151717;
            }

            ::-webkit-input-placeholder { /* WebKit browsers */
                color: #151717;
            }
            :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: #151717;
            }
            ::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: #151717;
            }
            :-ms-input-placeholder { /* Internet Explorer 10+ */
                color: #151717;
            }
        }
 
    }

    .align-right {
 		float: right;
 		margin-right: 18px;

 		@include mq(xsmall) {
			margin: 0;
		}
 	}


    .wpcf7-form-control-wrap {
    	@include mq(xsmall) {
			width: auto;
		}
    }

    .close-btn {
    	cursor: pointer;
    	font-size: 20px;
    	color: #404040;
        position: absolute;
        top:15px;
        right: 25px;
    }

    .seperator {
    	margin-left: 10px;
    	font-weight: bold;
    	margin-top: 0;
    	margin-bottom: 0;
    }

    .your-email {
    	margin-top: 0;

		//Mobile spacing between each of the input fields
    	@include mq(xsmall) {
			margin-top: 8px;
		}
    }

    .telephone {
    	margin-top: 0;

    	//Mobile spacing between each of the input fields
    	@include mq(xsmall) {
			margin-top: 8px;
		}
    }

    .radio-dispo {
    	width: 100%;
    	display: flex;
    	justify-content: flex-start;
    	flex-direction: row;
    	margin-top: 10px;
    	margin-bottom: 10px;

    	@include mq(xsmall) {
			flex-direction: column;
		}

    	span.wpcf7-list-item {
    		margin:0 20px 0 0;
    		display: flex;
    		flex-flow: row wrap;
  			justify-content: flex-start;
  			align-items: flex-start;

  			@include mq(xsmall) {
				margin: 10px 0;
			}

  			input  {
  				width: 20px;
  				margin-right: 10px;
  				margin-bottom: 0px;
  				margin-top: 1px;
  			}
    	}
    }
}


.c-formulaire-rendez-vous .wrapper form ::-webkit-input-placeholder { /* WebKit browsers */
    color: #151717;
}
.c-formulaire-rendez-vous .wrapper form :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #151717;
}
.c-formulaire-rendez-vous .wrapper form ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #151717;
}
.c-formulaire-rendez-vous .wrapper form :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #151717;
}
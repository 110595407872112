.c-breadcrumbs {
   margin: -40px 0 18px 0;
   padding: 0;
   position: relative;
   z-index: 10;

   .property-bar-fixed & {
      margin-bottom: 93px;
   }

   @include mq(large) {
      .property-bar-fixed & {
         margin-bottom: 18px;
      }
   }
}

.c-breadcrumbs ul,
.c-breadcrumbs li {
   margin: 0;
   padding: 0;
   float: left;
   list-style: none;
}

.c-breadcrumbs a {
   color: #fff;
   font-size: 0.9375rem;

   &:after {
      content: "\f105";
       font-family: FontAwesome;
       color: $color-grey-scorpion;
       font-size: 0.75rem;
       display: inline-block;
       margin: 0 10px;
   }

   &:hover,
   &:focus {
      text-decoration: underline;
   }
}

.c-breadcrumbs li:last-of-type a:after{
   display: none;
}
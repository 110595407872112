.c-courtiers {
	max-width: 600px;
	margin: auto;
}

.c-courtiers__single {
	width: calc(50% - 20px);
	float: left;
	margin: 0 10px;
	
	img {
		padding: 15px;
		background: $color-grey-mercury;
		display: inline-block;
		margin: 30px 0 15px 0;
	}

	span {
		display: block;
		font-family: $font-butler;
		color: $color-grey-dim;
		font-size: 1rem;
		margin-left: 10px;

		&:first-of-type {
			font-family: $font-poppins;
			font-weight: 600;
			font-size: 1rem;
			color: $color-text;
			font-size: 21px;
		}
	}

}

.decorative-half-gradient {
	position: relative;

	&:before {
		content: '';
		width: calc(50vw - 80px);
		height: calc(100% + 200px);
		background: linear-gradient(180deg,#fff 0,rgba(181,195,200,85));
		position: absolute;
		left: 0;
		top: -200px;
		bottom: 0;
		z-index: -1;
	}
}
.c-accordeon-container {
    margin: 40px 0px;
}

.c-accordeon {
    background: $color-grey-dark;
    margin: 10px 0px;
    transition: all 300ms ease;
    cursor: pointer;
/*
    &.plus_detail{

        .c-accordeon__content{
            //padding: 0 32px;
            //display: inline-block;
            display: table;

            p{
                color: $color-black;
                font-family: $font-regular;
                font-size: 16px;
            }
        }
    } */
}

.c-accordeon__title {
    padding: 15px 30px;
    color: white;
    position: relative;
    transition: all 300ms ease;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: -1px;
        height: calc(100% + 2px);
        width: 50px;
        background-size: auto 15px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: url('../images/arrow-right--big--white.png');
        transform-origin: 50% 50%;
        transform: translate(0px, -50%);
        transition: all 300ms ease;
    }

    &:before {
        position: absolute;
        content: "";
        right: 0px;
        top: 0px;
        height: 100%;
        width: 50px;
        background: $color-grey-scorpion;
    }

    &:hover {
        background: $color-grey-dark;
        color: white;

        &:after {
            background-image: url('../images/arrow-right--big--white.png');
        }
    }
}

.c-accordeon__content {
    display: none;
    background: white;
    width: 100%;
    border-top: none;
    border-collapse: collapse;

    tbody {
        width: 100%;
    }

    th {
        padding: 10px;
    }

    td {
        padding: 10px;
    }

    &, tbody, th, td {
       border: 1px solid #d9dfdf;
       text-align: left;
    }

    p{
        margin: 16px 10px;
    }
}

.c-accordeon.active {

    .c-accordeon__title {
        background: $color-grey-dark;
        color: white;
    }

    .c-accordeon__title:after {
        background-image: url('../images/arrow-right--big--white.png');
        transform: translate(0px, -50%) rotate(90deg);
    }

    .c-accordeon__content {
        display: table;
    }

    .c-caracteristiques.c-accordeon__content {
        display: flex;
    }

    .superficie-dimensions.c-accordeon__content {
        display: flex;
    }
}

.c-caracteristiques.c-accordeon__content {
    padding: 30px;
    display: none;
    flex-flow: row wrap;
}

.c-caracteristiques__row {
    width: 50%;
    margin-bottom: 30px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 992px) {
        width: 100%;
        padding-right: 0;
    }
}


.superficie-dimensions {
    display: none;
    flex-flow: row wrap;
    padding: 30px;

    &__col {
        width: 50%;
        margin-bottom: 30px;
        padding-right: 20px;
        font-size: 14px;
        line-height: 20px;

        @media (max-width: 992px) {
            width: 100%;
            padding-right: 0;
        }
    }
}
$color-input-hover: #0c0d0d;

.c-banner {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	min-height: 600px;
	background-color: $color-input-hover;
	background-size: cover;
	background-position: center;

	&.banner-homepage {
		height: 100vh;

		@media(max-width: 650px){
			background-image: url('../images/new/banner-homepage.jpg');
		}
	}

	&.banner-news > div {
		min-width: 600px;
	}

	@include mq (medium) {
		min-height: 350px;

		&.banner-news > div {
			min-width: auto;
		}
	}

	&.banner-news {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

}

.c-banner-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0.3;
    &--dark {
        @extend .c-banner-backdrop;
        background: rgba(0, 0, 0, 0.5);
	    opacity: 1;
    }
}

.c-banner__homepage-ctn {
	text-align: center;
	color: #fff;
	z-index: 2;

	h1 {
		font-family: $font-poppins;
		color: #fff;
		font-size: 65px;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 3px;

		span {
			font-family: $font-butler;
			color: $color-grey-scorpion;
			display: block;
			font-weight: 400;
			letter-spacing: 5px;
		}
	}

	p {
		font-size: 16px;
		font-style: italic;
		color: $color-grey-dim;
		letter-spacing: 2px;

		span {
			font-family: $font-poppins;
			color: $color-grey-outer-space;
			padding: 3px 6px 0px 6px;
			background: #fff;
			display: inline-block;
			font-style: normal;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 3px;
			margin-left: 7px;
			position: relative;
			top: 1px;
		}
	}

	.c-banner__play {
		display: inline-block;
		margin: 40px 0 0 0;

		img {
			width: 82px;
			height: auto;
			transform: scale(1);
			transition: .3s cubic-bezier(0.35, 0.85, 0.59, 1.42) all;
		}

		.c-banner__play-label {
			display: block;
			font-size: 16px;
			font-style: italic;
			color: $color-grey-dim;
			margin-top: 10px;
			letter-spacing: 2px;
			transform: translate(0, 0);
			transition: .3s cubic-bezier(0.35, 0.85, 0.59, 1.42) all;

			span {
				font-family: $font-poppins;
				color: #fff;
				font-style: normal;
				letter-spacing: 3px;
				display: inline-block;
				margin-left: 2px;
			}
		}

		&:hover,
		&:focus {

			img {
				transform: scale(1.3);
			}

			.c-banner__play-label {
				transform: translate(0, 10px);
			}
		}
	}
}

input[type="text"].c-banner__search {
	width: 100%;
	padding: 20px 50px 20px 25px;
	border: 1px solid $color-grey-mercury;
	background: none;
	font-family: $font-poppins;
	font-weight: 600;
	font-size: 16px;
	text-transform: uppercase;
	color: #fff;
	opacity: 1;
	margin-bottom: 0;
	transition: 0.25s ease all;

	&::-webkit-input-placeholder {
		transition: all 0.25s ease;
	}

	&:hover,
	&:focus {
		background: $color-grey-mercury;
		color: $color-input-hover;

		&::-webkit-input-placeholder {
			color: $color-input-hover;
		}
	}
}

.c-banner__search-ctn {
	position: relative;
	width: 500px;
	margin: 0 auto;
	background: #141616;
	padding: 12px;
	margin-bottom: 25px;

	@include mq(small) {
		width: 100%;
	}
}

.c-banner__search-btn {
	position: absolute;
	right: 25px;
	top: calc(50% - 19px);
	border: none;
	outline: none;
	cursor: pointer;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	padding: 0;
	background: none;
	transform: scale(1);
	background: $color-input-hover;
	transition: 0.25s ease all;

	svg {
		width: 22px;
		height: 22px;
		fill: #fff;
		position: relative;
		top: 2px;
		transition: 0.25s ease all;
	}

	&:hover,
	&:focus {
		transform: scale(1.1);
	}
}

.box {
	position: relative;
}

.c-banner__property-details {
	@extend .c-last-properties__single-ctn;
}


.js-property-carousel-header {
	background: black;
	height: calc(100vh - 140px);

	@media (max-width: 1100px) {
		height: 60vw;
		min-height: 450px;
	}

	@media (max-width: 768px) {
		justify-content: flex-start;
	}

	.slick-list {
		overflow: visible;
		height: 100%;
	}

	.slick-track {
		//display: flex;
		//flex-flow: row wrap;
		//justify-content: center;
		height: 100%;

		@media (max-width: 768px) {
			justify-content: flex-start;
		}
	}

	.slick-slide {
		opacity: 0.35;
		transition: opacity 500ms ease;
	}

	.slick-current {
		opacity: 1;
	}
}
.c-projets-futurs {
	text-align: center;
	padding: 60px 0;
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(181,195,200,0.5) 100%);
}

.bubble-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
    &--push-bottom {
        @extend .bubble-wrapper;
        margin-bottom: 40px;
    }
}

.bubble-1 {
	width: 480px;
	height: 480px;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	right: -20px;
	z-index: 2;
	transform: translate(0, 300px);
	padding: 20px;
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(181,195,200,1) 100%);

	@include mq(small) {
		border-radius: 0;
	}

	&.no-anim {
		transform: none;
	}

	.bubble-1-ctn {
		background: url(../images/new/cta-2.jpg);
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		text-align: left;
		position: relative;
		padding: 110px 30px 0 50px;

		@include mq(small) {
			border-radius: 0;
		}

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			background: rgba(31, 31, 31, 0.8);
			z-index: 1;
			transition: 0.35s ease all;

			@include mq(small) {
				border-radius: 0;
			}
		}

		h2 {
			margin: 0;
		}

		p {
			color: $color-grey-dim;
			font-size: 16px;
		}

		a {
			color: $color-grey-dim;
			font-size: 18px;
			margin-top: 10px;
			letter-spacing: 1px;
			font-style: italic;
			display: block;
			text-align: center;
			margin-top: 30px;

			i {
				display: inline-block;
				transform: translate(0, 0);
				transition: 0.35s ease all;
			}

			&:hover,
			&:focus {

				i {
					transform: translate(7px, 0);
				}
			}
		}

		* {
			z-index: 2;
			position: relative;
		}
	}

	&:hover,
	&:focus {
		.bubble-1-ctn:before {
			background: rgba(31, 31, 31, 0.9);
		}
	}

	@include mq(medium) {
		right: 0;
	}
}

.bubble-2 {
	width: 300px;
	height: 300px;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	left: -10px;
	transform: translate(0, 300px);
	background: linear-gradient(180deg,#fff 0,#b5c3c8);
	padding: 110px 20px 0 50px;

	p {
		text-align: left;
	}

	&.no-anim {
		transform: none;
	}

	&:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 80px;
		width: 6px;
		height: 20px;
		margin-left: -3px;
		background: url(../images/new/arrow-down.png);
		background-size: 100%;
		transition: 0.35s ease all;
	}

	&:hover,
	&:focus {
		&:before {
			transform: translate(0, 7px);
		}
	}

	@include mq(medium) {
		display: none;
	}
}



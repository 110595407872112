.c-sidebar-tags {
   width: 300px;
   float: right;
   padding: 0 0 30px 30px;

   @include mq(medium) {
      width: 100%;
      margin: 30px 0;
      padding: 0;
   }
}

.c-sidebar-tags__title {
   font-size: 1.5rem;
   font-weight: 700;
   color: $color-black;
   margin-top: 0;
}

.c-sidebar-tags__title span {
   color: $color-grey-scorpion;
}

.c-sidebar-tags__single {
   font-size: 0.9375rem;
   color: #fff;
   padding: 10px 25px;
   display: inline-block;
   background: #d9dfdf;
   border-radius: 20px;
   margin: 0 10px 15px 0;
   transition: 0.15s linear background;

   &:hover,
   &:focus,
   &:active {
      background: $color-grey-scorpion;
   }
}

* {
	box-sizing: border-box;
	outline: none;
}

.wow {
	visibility: hidden;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-regular;
}

body {
	//@include breakpoint-label;
	background: #fff;
	font-size: $font-size-base;
	line-height: $line-height-base;

	&.no-scroll {
		overflow: hidden;
	}
}

a {
	color: $color-grey-scorpion;
	text-decoration: none;
}

h1 {
	color: $color-black;
	font-size: 3.5rem;
	font-weight: 400;

	@include mq(medium) {
		font-size: 2.25rem;
	}
}

h2 {
	color: $color-black;
	font-size: 3.125rem;
	font-weight: 400;

	@include mq(medium) {
		font-size: 2rem;
	}
}

h3 {
	color: $color-black;
	font-size: 2.75rem;
	font-weight: 400;

	@include mq(medium) {
		font-size: 1.75rem;
	}
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="tel"],
textarea {
	color: #fff;
	transition: 0.15s linear all;
}


// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: $color-placeholder;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $color-placeholder;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $color-placeholder;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: $color-placeholder;
}

.c-sold-this-year {
   background: white;
   color: $color-grey-scorpion;
   padding: 50px 0px;
}

.c-sold-this-year__count {
   font-size: 7rem;
   display: inline-block;
   position: relative;
   transform-origin: center center;

   &.animate {
      animation: pop 0.8s normal forwards ease-in-out;
   }

   &:before {
      content: "";
      display: block;
      height: 1px;
      width: 150px;
      background: #d8d8d8;
      position: absolute;
      right: calc(100% + 20px);
      top: 50%;
      transition: all 300ms ease;

      @include mq(medium) {
         width: 50px;
      }
   }

   &:after {
      content: "";
      display: block;
      height: 1px;
      width: 150px;
      background: #d8d8d8;
      position: absolute;
      left: calc(100% + 20px);
      top: 50%;
      transition: all 300ms ease;

      @include mq(medium) {
         width: 50px;
      }
   }
}

.c-sold-this-year__title {
   color: $color-black;
   margin-top: 0px;
   display: block;

   strong {
      color: $color-grey-scorpion;
      display: block;
      font-weight: 400;
   }
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

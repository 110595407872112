.c-nav-grey {
   height: 40px;
   width: 100%;
   background: #080808;

   @include mq(large) {
      display: none;
   }
}

.c-nav-grey__social {
   float: left;

   span {
      font-size: 14px;
      font-weight: bold;
      color: $color-grey-light;
      display: inline-block;
      line-height: 40px;
      margin-right: 10px;
   }
   
   a {
      display: inline-block;
      margin: 0 5px;
      color: #fff;
      transition: 0.15s linear color;

      &:hover,
      &:focus {
         color: $color-grey-scorpion;
      }
   }
}

.c-nav-grey__menu {
   padding: 0;
   margin: 0;
   float: right;
   list-style: none;

   li {
      float: left;

      .c-footer__legal & {
         float: none;
         display: inline-block;
      }
   }
}

.c-nav-grey__link {
   font-family: $font-poppins;
   font-size: 10px;
   font-weight: 500;
   line-height: 40px;
   letter-spacing: 1px;
   color: #626262;
   padding: 0 15px;
   display: inline-block;
   text-decoration: none;
   background: transparent;
   transition: 0.25s linear color;

   &.c-nav-language__link {
      color: #fff;
   }

   b {
      color: #fff;
   }

   span {
      color: #626262;
   }

   &:hover,
   &:focus {
      color: #fff;

      span {
         color: #626262;
      }
   }
}
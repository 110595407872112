.c-footer-inscription {

}

.c-footer-inscription__half {
   width: 50%;
   float: left;

   &:first-of-type {
      background: #080808;
   }

   &:last-of-type {
      padding: 60px 0 60px 60px;
   }

   @include mq(medium) {
      width: 100%;
      float: none;
   }
}

.c-footer-inscription__link {
   font-family: $font-poppins;
   text-transform: uppercase;
   letter-spacing: 1px;
   font-weight: 600;
   color: $color-text;
   position: relative;
   display: inline-block;
   padding-right: 100px;

   span {
      font-family: $font-butler-light;
      display: block;
      letter-spacing: 2px;
      color: $color-grey-scorpion;
   }

   &:after {
      content: '';
      width: 20px;
      height: 6px;
      background: url('../images/new/arrow-right--bold.png');
      background-size: 20px;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -3px;
      transform: translate(0, 0);
      transition: 0.3s ease-out all;
   }

   &:hover,
   &:focus {
      &:after {
         transform: translate(10px, 0);
      }
   }
}

.c-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.c-tabs li {
    float: left;
    margin: 0;
    padding: 0;
    flex-grow: 1;

    &:hover{
        cursor: pointer;
    }
}

.c-tabs__panel {
    display: none;
    position: relative;
    width: 100%;

    &.active {
        display: flex;
        flex-flow: column wrap;
    }

    &.more-details {
        background: #eaeaea;
        overflow: hidden;
        padding: 15px 20px;
    }

    iframe {
        width: 100%;
        height: 410px;

        @media (max-width: 1050px) {
            height: 370px;
        }

        @media (max-width: 975px) {
            height: 323px;
        }

        @media (max-width: 850px) {
            height: 260px;
        }

        @media (max-width: 767px) {
            height: 53vw;
        }
    }
}

.c-tabs__button {
    text-align: center;
    display: block;
    font-size: 0.875rem;
    color: #fff;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-black;
    transition: 0.15s linear background;

    &:hover,
    .active & {
        background: $color-grey-scorpion;
    }

    @include mq(large) {
        font-size: 0;
    }
}

.c-tabs__button svg {
    display: inline-block;
    margin-left: 15px;
    fill: #fff;
    width: 30px;
    height: 30px;

    &.i-more {
        width: 25px;
        height: 25px;
        position: relative;
        top: -3px;
    }
}

.c-tabs__map {
    height: 500px;
    width: 100%;
}

.c-tabs__carousel-image {
    display: block;
    height: 400px;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
}

.js-thumbnail {
    margin: 12px 12px 0 0;
    border: 4px solid transparent;
    box-sizing: border-box;
    transition: 0.15s linear border;

    &:hover,
    &:focus,
    &.slick-current {
        border: 4px solid $color-grey-scorpion;
    }
}

.c-banner-slick {
    .c-tabs__carousel-image {
        height: 100%;
    }
}

.js-property-thumbnails {
    width: 100%;
}

.js-property-carousel {
    width: 100%;
}
.c-large-intro {
   background-color: #f9f7f8;
   background-image: url('../images/background-logo.png');
   background-repeat: no-repeat;
   background-position: center center;
   padding: 150px 0;
   position: relative;

   @include mq(large) {
      padding: 60px 0;
   }
}

.c-large-intro h1 {
   color: $color-black;
   font-size: 4.8675rem;
   line-height: 5rem;
   text-align: center;

   span {
      color: $color-grey-scorpion;
   }

   @include mq(large) {
      font-size: 3rem;
      line-height: 3.5rem;
   }
}

.c-large-intro p {
   font-size: 0.9375rem;
   line-height: 1.25rem;
   color: $color-grey-light;
   text-align: center;
   padding: 0 80px;
   margin-bottom: 60px;

   @include mq(large) {
      padding: 0;
   }
}

.c-large-intro .c-button--big,
.c-large-intro .c-button--black {
   margin: 10px;
}


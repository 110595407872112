.c-home-video-container {
    position: relative;
    overflow: hidden;
 }

 .video_bg_parrent{
    height: 100vh;
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: hidden;
 }

 .video_bg_wrapper{
       width: 100%;
       height: 100%;
       position: absolute;
       top: 0;
       left: 0;
       z-index: 2;

       .video_bg{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
       }
 }

 .c-banner-homepage {
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-size: cover;
    background-position: center;
    text-align: center;
    position: relative;
    z-index: 2;

    @include mq(medium) {
       height: auto;
       padding: 80px 0;
    }
 }

 .c-banner-homepage__title-over {
    font-size: 2.8125rem;
    width: 100%;
    color: #fff;
    display: block;

    span {
       color: $color-grey-scorpion;
    }

    @include mq(medium) {
       font-size: 1.8rem;
    }
 }

 .c-banner-homepage__title-main {
    color: #fff;
    font-size: 3.75rem;
    line-height: 1;
    margin: 0;
    font-weight: 900;
    width: 100%;
    text-transform: uppercase;
    display: inline-block;

    svg {
       width: 55px;
       height: 55px;
       display: inline-block;
       margin-right: 10px;
    }

    @include mq(medium) {
       font-size: 2.2rem;

       svg {
          width: 35px;
          height: 35px;
       }
    }
 }

 .c-banner-homepage__title-below {
    font-size: 2.8125rem;
    line-height: 1.2;
    font-weight: 400;
    width: 100%;
    color: $color-grey-scorpion;
    display: block;

    @include mq(medium) {
       font-size: 1.8rem;
    }
 }

 .c-banner-homepage__conditions {
    font-size: 0.8125rem;
    font-weight: 400;
    color: #fff;
    display: block;
    margin-top: 10px;
 }

 @media(max-width: 1025px){

    .video_bg_parrent{
       top: 0;
    }
 }

 @media(max-width: 650px){

    .video_bg{
       display: none;
    }
 }

 @media (min-aspect-ratio: 16/9) {
   .video_bg_wrapper { height: 300%; top: -100%; }
 }
 @media (max-aspect-ratio: 16/9) {
   .video_bg_wrapper { width: 300%; left: -100%; }
 }
.c-last-properties {
	background: $color-grey-cod;
	background-image: url('../images/new/bg-futur-projects.jpg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: bottom center;
	overflow: hidden;
}

.c-last-properties__single {
	width: 100%;
	height: 650px;
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
	display: block;
	cursor: pointer;

	.c-button--large-black {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -70px;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: 0.5s ease all 0.1s, 0.5s ease color 0s, 0.5s ease background 0s;
		text-align: center;

		@media (max-width: 768px) {
			left: 20px;
			right: 20px;
			transform: none;
			font-size: 14px;
		}
	}

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.64) 70%,rgba(0,0,0,.9));
		transition: 0.5s ease all 0.2s;
	}

	&:hover,
	&:focus {

		.c-last-properties__image {
			transform: scale(1.2);
		}

		.c-last-properties__single-ctn {
			transform: translate(0, 120px);
			opacity: 0;
		}

		.c-button--large-black {
			opacity: 1;
			margin-top: 0;
			transition: 0.5s ease all 0.4s, 0.5s ease color 0s, 0.5s ease background 0s;
		}

		&:after {
			opacity: 0;
		}
	}
}

.c-last-properties__image {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-position: center;
	transform: scale(1);
	transition: 0.5s ease all;
}

.home .c-last-properties__single-ctn,
.page-template-template-inscriptions .c-last-properties__single-ctn,
.page-template-template-ventes .c-last-properties__single-ctn {
	bottom: 0;
	position: absolute;
}

.c-last-properties__single-ctn {
	width: 100%;
	height: 100px;
    background: black;
	border-top: 1px solid $color-grey-scorpion;
	padding: 10px 25px 10px;
	z-index: 9999;
	transition: 0.5s ease all 0.2s;
	opacity: 1;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	@media (max-width: 768px) {
		height: auto;
		padding: 30px 20px;
		flex-flow: column wrap;
	}

	.l-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		@media (max-width: 768px) {
			padding: 0;
			flex-flow: column wrap;
		}

		&:after {
			display: none;
		}
	}

	.left-part {
		text-align: left;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: flex-start;

		@media (max-width: 768px) {
			margin-bottom: 10px;
		}
	}

	.right-part {
		text-align: right;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: flex-end;

		@media (max-width: 768px) {
			align-items: flex-start;
		}
	}

	.primary {
		font-size: 16px;
		font-family: $font-poppins;
		font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		display: block;
	}

	.secondary {
		font-size: 10px;
		font-family: $font-poppins;
		color: #adadad;
		text-transform: uppercase;
		display: block;
		margin-bottom: 5px;

		@media (max-width: 768px) {
			margin-bottom: 20px;
		}

	}
}

.is-sold {
	cursor: initial;

	.c-button--large-black {
		display: none !important;
	}
}
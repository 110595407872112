/* =======================================================================
Layout
========================================================================== */

.l-page-container {
	padding: 0 100px;

	@include mq(xxl) {
		padding: 0 15px;
	}
}

.l-container {
    @include container;
    width: 100%;
    padding: 0 15px;
}

.l-container--small {
	width: 100%;
	max-width: 750px;
	margin: 0 auto;
	padding: 0 15px;

	@include mq(large) {
		width: 100%;
	}
}

.l-row {
	clear: both;
	overflow: hidden;
	margin: 0 -0.8333333333%;
}

.l-negative-margin {
	margin: 0 -0.8333333333%;
}

.l-small-padding {
	padding-top: 40px;
	padding-bottom: 40px;

	@include mq(small) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.l-6-dk {
	@include span(6);

	@include mq(medium) {
		width: 100%;
	}
}

.l-flex {
	display: flex;
	flex-wrap: wrap;
}

.l-section {
	padding-top: 70px;
	padding-bottom: 80px;

	& > h1,
	& > h2,
	.section-title {
		margin-top: 0;
	}

	&.no-pad-top{
		padding-top: 0;
	}

	&.no-pad-bot{
		padding-bottom: 0;
	}

	@include mq(medium) {
		padding-top: 40px;
		padding-bottom: 50px;
	}
}

.l-section--grey {
	@extend .l-section;
	background: #f7f7f7;
	border-top: 1px solid #d9dfdf;
}

.section-gradient {
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(181,195,200,0.5) 100%);
}

// Articles ---------

.l-article {
	margin: 60px 0 80px 0;
	overflow: hidden;

	&.no-margin {
		margin-bottom: 0 !important;
	}

	@include mq(medium) {
		margin: 60px 0;
	}
}

.l-article__content {
	float: left;
	width: calc(100% - 400px);

	&.is-full {
		width: 100%;
	}

	> h1 {
		margin-top: 0;
	}

	h1,
	h3,
	h4 {
		font-family: $font-poppins;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
	}

	h1 {
		font-size: 40px;
	}

	h4 {
		font-size: 14px;
	}

	@include mq(medium) {
		width: 100%;
	}
}

.l-article__content--centered {
	@extend .l-article__content;
	float: none;
	width: calc(100% - 260px);
	margin: 0 auto;

	@include mq(medium) {
		width: 100%;
	}

}

.l-article__content--with-border {

	@extend .l-article__content;
	width: calc(100% - 310px);
	padding: 0 40px 30px 0;
	border-right: 1px solid #d9dfdf;

	@include mq(medium) {
		width: 100%;
		padding: 0;
		border: none;
	}
}

.l-article__content > h2 {
	margin-top: 0;
}

.l-article__content h2 {
	font-family: $font-butler;
	font-size: 1.8rem;
	color: $color-blue-light-cyan;
	text-transform: uppercase;

	@include mq(medium) {
		font-size: 1.25rem;
	}
}

.l-article__content h3 {
	font-size: 1rem;
	font-weight: 700;
	color: $color-grey-outer-space;
	margin-bottom: 1rem;

	@include mq(medium) {
		font-size: 1.25rem;
	}
}

.l-article__content p {
	font-family: $font-regular;
	font-size: 0.875rem;
	line-height: 1.4;
	margin: 1rem 0;
	color: $color-grey-text;

	@include mq(medium) {
		font-size: 1rem;
	}
}

.l-article__content img:not(.js-thumbnail) {
	padding: 15px;
	background: $color-grey-mercury;
	display: inline-block;
	margin: 30px 0;
}

.l-article__content blockquote {
	position: relative;
	padding: 0 60px;
	margin: 30px auto;
	max-width: 600px;

	p {
		font-family: $font-regular;
		font-style: italic;
		font-size: 1.375rem;
	}

	&:before,
	&:after {
		content: '';
		background: url('../images/new/quotes.png');
		width: 24px;
		height: 19px;
		background-size: 24px;
		display: inline-block;
		position: absolute;
	}

	&:before {
		background-position: top;
		top: -10px;
		left: 0;
	}

	&:after {
		background-position: bottom;
		bottom: -10px;
		right: 0;
	}
}

.l-article__content .back-button {
	font-size: 1.5rem;
	font-weight: 700;
	color: $color-black;
	display: inline-block;
	transition: 0.15s linear all;

	&:after {
		content: "\f105";
	    font-family: FontAwesome;
	    color: $color-blue;
	    display: inline-block;
	    margin: 0 0 0 15px;
	}

	&:hover,
	&:focus {
		color: $color-grey-light;
	}

	@include mq(medium) {
		font-size: 1.25rem;
	}
}

.l-text p {
	font-size: 0.9375rem;
	line-height: 2rem;
	margin: 1.75rem 0;
	color: $color-grey-light;
}

.l-media-container {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;

	h2 {
		font-family: $font-poppins;
		font-size: 2.5rem;
		font-weight: 600;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align: center;
	}

	h3 {
		font-family: $font-butler;
		font-size: 1.5625rem;
		text-transform: uppercase;
		color: $color-blue-light-cyan;
		text-align: center;

		span {
			color: $color-text;
		}
	}

	p {
		font-family: $font-butler;
		font-size: 0.875rem;
		text-align: center;
		line-height: 1.5;
		color: $color-grey-text;
	}
}





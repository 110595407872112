.c-property-layout {
   h1 {
      font-family: $font-poppins;
      font-size: 2.5rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      text-align: center;
   }

   h2 {
      font-family: $font-butler;
      font-size: 1.56rem;
      text-align: center;
      color: $color-grey-scorpion;
      text-transform: uppercase;
      letter-spacing: 1px;
   }

   p {
      font-family: $font-regular;
      font-size: 0.875rem;
      color: $color-grey-text;
      text-align: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 550px;
   }
}

.c-property-layout__carousel {
   background: $color-grey-mercury;
   padding: 20px 0;
}

.property-carousel-arrow {
   width: 60px;
   height: 60px;
   color: #fff;
   background: #080808;
   position: absolute;
   top: calc(50% - 30px);
   z-index: 2;
   font-size: 30px;
   border: none;
   outline: none;
   border: 1px solid #080808;
   transition: 0.25s ease all;

   &:hover,
   &:focus {
      background: #fff;
      color: #080808;
   }
}

.js-slide-left {
   left: 0;
}

.js-slide-right {
   right: 0;
}

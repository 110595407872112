// normal state
.c-news-summary {
   @include span(4);
   background-size: cover;
   background-position: center top;
   padding: 25px;
   min-height: 325px;
   margin-bottom: gutter(6);
   position: relative;
   overflow: hidden;

   &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.6);
      transition: 0.25s linear background;
   }

   .section-title {
      margin: 0 0 50px 0;
   }

   @include mq(medium) {
      @include span(6);
      margin-bottom: gutter(8);
   }

   @include mq(small) {
      width: 100%;
      min-height: 250px;
      margin: 0 0 15px 0;
   }
}

// hover state
.c-news-summary:hover,
.c-news-summary:focus,
.c-news-summary:active {

   &:before {
      background: rgba(35, 31, 32, 0.8);
   }

   .c-news-summary__more:after {
      background: $color-black;
      color: $color-grey-scorpion;
   }
}

.c-news-summary__date {
   display: block;
   color: $color-grey-scorpion;
   font-size: 0.875rem;
   font-weight: 400;
   position: relative;
   z-index: 2;
}

.c-news-summary__title {
   display: block;
   color: #fff;
   font-size: 1.25rem;
   font-weight: 700;
   margin-bottom: 15px;
   position: relative;
   z-index: 2;
}

.c-news-summary__excerpt {
   display: block;
   color: #fff;
   font-size: 0.9375rem;
   position: relative;
   z-index: 2;
}

.c-news-summary__more {
   display: block;
   color: #fff;
   font-size: 1.0625rem;
   line-height: 3.125rem;
   font-weight: 700;
   font-weight: 400;
   position: absolute;
   bottom: 0;
   right: 0;
   z-index: 2;

   &:after {
      content: '\f105';
      font-family: FontAwesome;
      display: inline-block;
      margin-left: 22px;
      color: #fff;
      background: $color-grey-scorpion;
      padding: 0 22px;
      height: 51px;
      transition: 0.25s linear all;
   }
}








.c-recently-sold {
	border-top: 1px solid #d9dfdf;
	margin: 40px 0 0 0;
}

.c-recently-sold__single {
	@include span(4);
	min-height: 250px;
	position: relative;
	background-position: center;
	background-size: cover;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0px;
		top: 0px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 200px auto;
		transform: rotate(-20deg);
	}

	&.fr:after {
		background-image: url('../images/sold-fr.svg');
	}

	&.en:after {
		background-image: url('../images/sold-en.svg');
	}

	@include mq(medium) {
		width: 100%;
		margin-bottom: 20px;

		&:after {
			right: 20px;
		}
	}
}
.c-footer-ctas {
   overflow: hidden;

   .l-container {
      font-size: 0;
   }

   @include mq(medium) {
      display: none;
   }
}

.c-footer-ctas__link {
   display: flex;
   align-items: center;
   min-height: 200px;
   width: 33%;
   border-left: 1px solid #ebe7e7;
   padding: 0 40px;
   margin: 0;
   text-align: left;
   vertical-align: bottom;
   box-shadow: 0px 0px 40px 2px rgba(25,23,25,0);
   transition: 0.15s linear all;

   &:last-of-type {
      border-right: 1px solid #ebe7e7;
   }
}

.c-footer-ctas__link svg {
   float: left;
   width: 60px;
   height: 75px;
   transition: 0.15s linear all;

   &.anim-stroke {
      stroke: $color-black;
      stroke-width: 3px;
   }

   &.svg-refer {
      width: 50px;
   }
}

.c-footer-ctas__text {
   float: left;
   width: calc(100% - 80px);
   margin-left: 20px;
   display: inline-block;
}

.c-footer_ctas__desc {
   font-size: 0.9375rem;
   font-weight: 400;
   color: $color-grey-light;
   transition: 0.15s linear all;
}

.c-footer-ctas__link-title {
   display: block;
   color: $color-black;
   font-size: 1.25rem;
   font-weight: 700;
   transition: 0.15s linear all;

   span {
      color: $color-grey-scorpion;
      transition: 0.1s linear all 0s;
   }
}


.c-footer-ctas__link:hover,
.c-footer-ctas__link:focus,
.c-footer-ctas__link:active {
   background: $color-grey-scorpion;
   box-shadow: 0px 0px 40px 2px rgba(25,23,25,0.3);
   border-color: transparent;

   .c-footer-ctas__link-title {
      color: #fff;
   }

   .c-footer-ctas__link-title span {
      color: #fff;
      transition-delay: 0.05s;
   }

   .c-footer_ctas__desc {
      color: #fff;
   }

   svg {
      fill: #fff;

      &.anim-stroke {
         stroke: #fff;
      }
   }
}

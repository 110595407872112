.c-advanced-search-bar {
   background: $color-grey-lightish;
   border-top: 1px solid #dae0e0;
   border-bottom: 1px solid #dae0e0;
   margin: 20px 0 30px 0;
   max-height: 1000px;
   transition: all 0.5s ease;
   opacity: 1;

   &.closed {
      max-height: 0;
      border-width: 0px;
      opacity: 0;
      overflow: hidden;
   }
}

.advanced-search {
   font-size: 20px;
   margin: 30px 20px 10px 20px;

   &:after {
      content: "";
       display: inline-block;
       width: 9px;
       height: 5px;
       background: url(../images/new/arrow-down.png);
       background-size: 100%;
       margin-left: 10px;
       position: relative;
       top: -2px;
   }

   a {
      transition: all 0.15s ease;
      color: $color-black;

      span {
         color: $color-grey-scorpion;
      }

      &:hover {
         color: $color-grey-scorpion;
      }
   }
}

.c-advanced-search-bar .l-container {
   position: relative;
   padding-top: 50px;
   padding-bottom: 50px;
}

.c-advanced-search-bar__close {
   position: absolute;
   right: 10px;
   top: 0;
   color: $color-grey-scorpion;
   font-size: 28px;
   border: none;
   background: none;
   outline: none;
   cursor: pointer;
   padding: 5px 15px;
   margin: 0;

   &:hover,
   &:focus {
      color: $color-black;
   }
}

.c-advanced-search-bar__reset {
   float: right;
   color: $color-grey-light;
   font-size: 14px;
   line-height: 32px;
   padding-right: 50px;
   position: relative;
   border: none;
   background: none;
   outline: none;
   transition: all 0.15s ease;

   svg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 4px;
      right: 0;
      margin: 0 5px 0 7px;
      stroke: $color-grey-scorpion;
      transform: scale(1);
      transition: all 0.15s ease;
   }

   &:hover,
   &:focus {
      color: $color-grey-scorpion;
      svg {
         transform: scale(1.1);
      }
   }
}

.c-advanced-search-bar form {
   margin: 0 auto;

   @include mq(medium) {
      width: 100%;
   }
}

.c-advanced-search-bar h3 {
   font-size: 1.25rem;
   font-weight: normal;
   float: left;
   display: inline-block;
   margin-top: 0;
}

.c-advanced-search-bar .dk-select,
.c-advanced-search-bar .dk-select-multi {
   width: 47.5%;
   margin: 1%;

   @include mq(small) {
      width: 100%;
      margin: 10px 0;
   }
}

.c-advanced-search-bar .dk-selected  {
   padding: 10px 18px;
}

.c-advanced-search-bar .c-drag-bar {
   padding-left: 7px;
   width: 50%;
   float: left;

   @include mq(small) {
      width: 100%;
      padding: 0 7px;
   }
}

.c-advanced-search-bar .c-side-search__drag-label {
   width: 50%;

   @include mq(small) {
      width: 100%;
   }
}

.c-advanced-search-bar .c-side-search__drag-label {
   padding-left: 7px;
}

.c-advanced-search-bar .c-button--big {
   float: left;
   margin: 0 0 0 60px;

   @include mq(small) {
      margin: 20px 0 0 0;
   }
}

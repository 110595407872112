.section-title {
	color: $color-grey-outer-space;
	font-family: $font-poppins;
	font-size: 40px;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 600;
	text-align: center;
	margin: 100px 0 20px 0;

	&.on-dark {
		color: #fff;
	}

	.c-banner & {
		color: #fff;
		margin-top: 175px;
		line-height: 1.1;

		span {
			font-family: $font-butler;
			color: $color-blue-light-cyan;
			display: block;
		}
	}

	.banner-news & {
		text-align: left;
		text-transform: none;
	}

	@include mq(medium) {
		font-size: 28px;
		margin: 40px 0 10px;

		.c-banner & {
			margin-top: 135px;
		}
	}
}

.section-subtitle {
	font-size: 16px;
	font-family: $font-regular;
	font-style: normal;
	font-weight: normal;
	color: $color-grey-dim;
	text-align: center;
	max-width: 600px;
	margin: 20px auto 40px auto;

	&.on-dark {
		color: $color-grey-dim;
	}

	.c-banner & {
		color: $color-grey-dim;

		@include mq(medium) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.banner-news & {
		text-align: left;
		font-family: $font-butler;
		font-size: 1rem;
		color: $color-blue-light-cyan;
	}
}

.decorative-bar {
	position: relative;
	margin-bottom: 40px;

	&--above {
		position: relative;
		padding-top: 50px;

		&:before {
			content: '';
			position: absolute;
			bottom: auto;
			top: 0;
			left: 50%;
			width: 1px;
			height: 15px;
			background: $color-outer-space;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 1px;
		height: 15px;
		background: $color-outer-space;
		display: none;

		.c-banner & {
			background: #fff;
		}
	}
}

.weird-title {
	font-family: $font-poppins;
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 1.2;

	span {
		display: block;

		&:last-child {
			padding-left: 80px;
		}
	}

	@include mq(medium) {
		font-size: 1.8rem;
		text-align: center;

		span {
			display: inline-block;
		}

		span:last-child {
			padding-left: 0;
		}
	}
}

.c-highlight {
   background: #f8f9f9;
   padding: 35px 30px;
   margin: 40px 0;
   border: 1px solid #d9dfdf;
}

.c-highlight h3 {
   margin-top: 0;
   font-size: 1.5rem;
   color: $color-black;
   margin-bottom: 0.875rem;
}

.c-highlight h3 span {
   color: $color-grey-scorpion;
}

.c-highlight p {
   font-size: 1.25rem;
   color: #767676;
   margin: 0 0 10px 0;

   &:last-of-type {
      margin-bottom: 0;
   }
}
.c-sharing {
   padding: 50px 0 60px 0;
   text-align: center;
   font-size: 0;

   .c-footer-inscription & {
      padding: 60px 0;
   }
}

input.c-sharing__url {
   display: none;
    width: 100%;
    color: $color-grey-scorpion;
    padding: 10px 20px;
    font-size: 18px;
    margin: 20px;
    border-radius: 100px;
    text-align: center;

    &.active {
      display: block;
    }
}

.c-sharing h2 {
   font-family: $font-poppins;
   font-size: 1rem;
   letter-spacing: 1.5px;
   text-transform: uppercase;
   color: $color-grey-outer-space;
   display: inline-block;
   font-weight: 700;

   .c-footer-inscription & {
      color: #fff;
   }

   @include mq(small) {
      display: block;
   }
}

.c-sharing__single {
   display: inline-block;
   background: transparent;
   cursor: pointer;
   margin: 0 10px;
   padding: 0 10px;

   i {
      font-size: 1.25rem;
      color: $color-black;
      transition: 0.3s ease all;

      .c-footer-inscription & {
         color: #fff;
      }
   }

   &:hover,
   &:focus,
   &:active {

      i {
         transform: scale(1.7);
      }
   }
}

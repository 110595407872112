.c-page-loader {
    background: transparent;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 999999;
    opacity: 1;
    transition: all 1s ease;
}

.pano-left,
.pano-right {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	opacity: 1;
	transition: 0.3s ease all 0.5s;
}

.pano-left {
	left: 0;
	background: #333;
	transform: translate(0, 0);
}

.pano-right {
	right: 0; 
	background: #0c0d0d;
	transform: translate(0, 0);
	transition-delay: 0.15s;
}

.c-page-loader.closed {
	pointer-events: none;

	.pano-left {
		// transform: translate(-50vw, 0);
		transform: translate(0, -1000px);
		opacity: 0;
	}

	.pano-right {
		// transform: translate(50vw, 0);
		transform: translate(0, -1000px);
		opacity: 0;
	}

	.c-page-loader__loader {
		opacity: 0;
	}
}
.c-page-loader__loader {
	opacity: 1;
	display: inline-block;
    vertical-align: top;
    position: absolute;
    margin-left: -27px;
    margin-top: -27px;
    top: 50%;
    left: 50%;
    width: 55px;
    height: 55px;
    transform: rotate(0deg);
    animation: spinner ease 2s infinite;
    transition: 0.25s ease opacity 0.4s;

	.part-1,
	.part-2 {
		width: 50px;
		height: 50px;
		position: absolute;
	}

	.part-1 {
		bottom: 0;
		left: 0;
	}

	.part-2 {
		top: 0;
		right: 0;
		transform: rotate(180deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
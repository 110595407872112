.c-youtube-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.9);
	opacity: 0;
	transform: translate(0, -40px);
	pointer-events: none;
	transition: 0.5s ease all;

	.modal-open & {
		opacity: 1;
		transform: translate(0, 0);
		pointer-events: auto;
	}
}

.c-youtube-modal__button {
    position: absolute;
    top: 70px;
    right: 70px;
    font-size: 36px;
    color: #fff;
    border: none;
    font-family: '';
    font-weight: 100;
    outline: none;
    background: none;
    font-family: 'Verdana';
    cursor: pointer;

    @include mq(small) {
    	right: 40px;
    	top: 40px;
    }
}
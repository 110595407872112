.c-side-search {
   position: absolute;
   left: 0;
   top: 280px;
   transform: translate(-450px, 0%);
   z-index: 10;
   transition: 0.3s ease-out all 0.15s;

   &.is-visible {
      transform: translate(0, 0%);
   }

   @include mq(medium) {
      display: none;
   }
}

.c-side-search .dk-select {
   width: 100%;
   margin: 10px 0;
}

.c-side-search .dk-selected {
   padding: 10px 18px;
}

.c-side-search .dk-select-options {
   margin-top: 15px;
}

.c-side-search__content {
   width: 450px;
   min-height: 500px;
   float: left;
   background: #fff;
   padding: 40px;
   text-align: left;
   box-shadow: 0px 0px 40px 2px rgba(25,23,25,0.3);
}

.c-side-search__content button {
   margin-top: 20px;
}

.c-side-search__title {
   font-size: 1.25rem;
   font-weight: 400;
   text-align: left;
   margin: 0 0 20px 0;
}

.c-side-search__link {
   background-position: center;
   background-color: $color-black;
   background-repeat: no-repeat;
   width: 78px;
   height: 100%;
   display: block;
   text-indent: -99999px;
   position: absolute;
   left: 100%;
   cursor: pointer;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   transition: 0.15s linear all;

   &.fr {
      background-image: url('../images/search-property-fr.png');
   }

   &.en {
      background-image: url('../images/search-property-en.png');
   }

   &:hover,
   &:focus {
      border-left: 5px solid $color-grey-scorpion;
   }
}

.c-side-search__drag-label {
   color: $color-grey-light;
   font-size: 0.9375rem;
   font-weight: 400;
   display: block;
   margin: 20px 0;
}

.c-side-search__drag-label div {
   float: right;
   display: inline-block;
}

.c-side-search__drag-label span {
   color: $color-grey-scorpion;
   font-size: 0.75rem;
   font-weight: 400;
   display: inline-block;
}
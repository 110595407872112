#c-ventes-icon {
    width: 50px;
}

.c-ventes {

    h1 {
        font-family: $font-butler;
    }
}

.sold-property-count {
    margin: 30px 0 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:before {
        content: '';
        display: block;
        flex-grow: 1;
        height: 1px;
        background: white;
    }

    &:after {
        content: '';
        display: block;
        flex-grow: 1;
        height: 1px;
        background: white;
    }
}

.c-sold-counter {
    font-family: $font-butler;
    font-size: 80px;
    line-height: 85px;
    color: white;
    padding: 0 20px;
}

.ventes-temoignages {
    background: #222323;
    padding: 80px 0;

    h2 {
        font-family: $font-butler;
        font-size: 45px;
        line-height: 50px;
        margin: 0 0 20px;
        text-align: center;
        color: white;
    }

    span {
        display: block;
        text-align: center;
        font-family: $font-poppins;
        font-size: 14px;
        line-height: 22px;
        color: white;
        font-weight: 300;
        letter-spacing: 1px;
        margin-bottom: 60px;
    }
}

.ventes-temoignages-list {
    display: flex;
    flex-flow: row wrap;
    margin-right: -2%;

    @media (max-width: 650px) {
        margin-right: 0;
    }

    .ventes-temoignages-item {
        width: 48%;
        margin-right: 2%;

        @media (max-width: 650px) {
            width: 100%;
            margin-right: 0%;
        }

        iframe {
            width: 100%;
            height: 283px;
            margin-bottom: 20px;

            @media (max-width: 1090px) {
                height: 25vw;
            }

            @media (max-width: 650px) {
                height: 53vw;
                margin-bottom: 0;
            }
        }
    }
}

.top-spacer {
    margin-top: 40px;
}
.c-filter-dropdown {
	float: right;
	margin-top: 55px;

	&.no-margin {
		margin-top: 0;
	}

	@include mq(medium) {
		margin-top: 30px;

		&.mobile-full {
			float: none;
		}

		&.mobile-marg-bot{
			margin: 0 0 20px 0;
		}
	}

	@include mq(small) {
		float: left;
		margin: 20px 0;
	}
}

.c-filter-dropdown .dk-select {
	width: auto;
}

.c-filter-dropdown .dk-selected {
	border-color: transparent !important;
	padding-right: 40px;
	font-size: 17px;
}

.c-filter-dropdown .dk-selected:before {
	margin-top: -1px;
	font-size: 17px;
}

.c-filter-dropdown .dk-select-options {
	font-size: 17px;
}

.c-filter-dropdown .dk-selected:after  {
 	border-color: transparent !important;
}
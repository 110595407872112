$the-blue-for-this-one-paragraph-only: #1b5080;

.c-pourquoi {
	overflow: hidden;
	padding: 60px 0;
	background-size: cover;
	position: relative;
	background-position: center;

	p {
		font-size: 45px;
		line-height: 52px;
		font-weight: 600;
		color: $the-blue-for-this-one-paragraph-only;
		position: relative;
		z-index: 2;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(black, 0.85);
	}

	@include mq(medium) {

		p {
			font-size: 32px;
			line-height: 38px;
		}

		strong {
			font-size: 40px;
		}
	}
}
/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 599px),
	'medium': 		(max-width: 767px),
	'large': 		(max-width: 1024px),
	'xxl': 			(max-width: 1200px),
	'huge': 		(max-width: 1365px),
);

/* =======================================================================
Fonts
========================================================================== */

@font-face {
    font-family: 'Butler';
    src: url("../fonts/Butler_Stencil_Webfont/butler_medium_stencil-webfont.woff") format("woff");
}

@font-face {
    font-family: 'Butler-light';
    src: url("../fonts/Butler_Stencil_Webfont/butler_light_stencil-webfont.woff") format("woff");
}

/* =======================================================================
New colors
========================================================================== */
// Blue
$color-blue-light-cyan:   #b4c3c8;

// Grey
$color-grey-dim:          #6c6c6c;
$color-grey-outer-space:  #323738;
$color-grey-mercury:      #e3e3e3;
$color-grey-cod:          #0f0f0f;
$color-grey-scorpion:     #565656;
$color-grey-woodsmoke:    #161717;
$color-grey-gallery:      #f0f0f0;
$color-grey-alabaster:    #fcfcfc;
$color-grey-dove:         #626262;
$color-grey-chalice:      #a7a7a7;
$color-grey-text:         #767676;

// Green
$green:                   #27ae60;

/* =======================================================================
Variables
========================================================================== */

// Fonts stacks
$font-regular:            "Playfair Display", Arial, sans-serif;
$font-poppins:            'Poppins', sans-serif;
$font-butler:             'Butler', serif;
$font-butler-light:       'Butler-light', serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Colors
$color-blue:			  #00aeef;
$color-blue-medium:       #5bcef9;
$color-blue-medium-dark:  #075a7a;
$color-blue-darker:       #00a2de;


$color-black:			  #231f20;

$color-grey-dark:		  #231f20;
$color-grey-medium:       #8e8e8e;
$color-grey-light:		  #767676;
$color-grey-lightish:     #f8f9f9;
$color-grey-lighter:      #383838;
$color-grey-very-light:   #fcfcfc;
$color-grey-white:        #f7f7f7;


$color-orange:            #ff8626;
$color-orange-lighter:    #ff9c4e;

$color-outer-space:       #323738;

// Typography
$color-text:              $color-black;
$color-link:              $color-blue;


// Highlight
$color-placeholder:       #fff;


/* =======================================================================
Grid (Susy)
========================================================================== */

$susy: (
    flow: ltr, // ltr | rtl
    output: float, // float | isolate
    math: fluid, // fluid | static (requires column-width)
    column-width: false, // false | value
    container: 1100px, // length or % | auto
    container-position: center, // left | center | right | <length> [*2] (grid padding)
    last-flow: to,
    columns: 12,
    gutters: 5/20,
    gutter-position: split, // before | after | split | inside | inside-static (requires column-width)
    global-box-sizing: border-box, // content-box | border-box (affects inside/inside-static)
    debug: (
        image: hide,
        color: rgba(#66f, .2),
        output: background, // background | overlay
        toggle: top right,
    ),
    use-custom: (
        background-image: false, // look for background-image mixin
        background-options: false, // look for background-size, -origin and -clip and other compass/bourbon mixins
        box-sizing: false, // look for custom bix sizing mixin
        clearfix: false, // true = look for internal clearfix before using micro clearfix
        rem: false, // look for rem mixin
    )
);

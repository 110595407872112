.dk-select, .dk-select *, .dk-select *:before, .dk-select *:after, .dk-select-multi, .dk-select-multi *, .dk-select-multi *:before, .dk-select-multi *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.dk-select, .dk-select-multi {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5em;
  width: 200px;
  cursor: pointer; }

.dk-selected {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis; 
  color: #fff;
  border: 1px solid #fff;
  background: none;
  border-radius: 0;
  padding: 8px 25px 6px 12px;
  font-family: $font-poppins;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.1;
  text-transform: uppercase;
  transition: 0.25s ease all;
}
  .dk-selected:before, .dk-selected:after {
    display: none;
  }

.dk-selected-disabled {
  color: #BBBBBB; }

.dk-select .dk-select-options {
  position: absolute;
  display: none;
  left: 0; 
  font-size: 13px;
}

.dk-select-open-up .dk-select-options {
  margin-bottom: 15px;
  bottom: 100%; }

.dk-select-open-down .dk-select-options {
  margin-top: 12px;
  top: 100%; }

.dk-select-multi .dk-select-options {
  max-height: 10em; }

.dk-select-options {
  background: #0c0d0d;
  border: 1px solid #fff;
  color: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 200px;
  z-index: 100; 
  font-family: $font-poppins;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 9px;
    border-color: transparent transparent #fff;
    position: absolute;
    top: -9px;
    left: 25px;
  }
}

.dk-select-open-up .dk-select-options:before {
  top: auto;
  bottom: -9px;
  border-width: 8.5px 8.5px 0 9px;
  border-color: #fff transparent transparent transparent;
}

.dk-option-selected {
  //background-color: $color-grey-alabaster;
  color: $color-grey-alabaster; }

.dk-select-options-highlight .dk-option-selected {
  background-color: transparent;
  color: inherit; }

.dk-option {
  padding: 8px 15px;
  font-size: 12px;

  &:hover {
    background: #fff;
    color: #0c0d0d !important;
  }
}

.dk-select-options .dk-option-highlight {
  color: $color-grey-alabaster; }

.dk-select-options .dk-option-disabled {
  color: #BBBBBB;
  background-color: transparent; 
  display: none; // added by phil
}

.dk-select-options .dk-option-hidden {
  display: none; }

.dk-optgroup {
  border: solid #CCCCCC;
  border-width: 1px 0;
  padding: 0.25em 0;
  margin-top: 0.25em; }
  .dk-optgroup + .dk-option {
    margin-top: 0.25em; }
  .dk-optgroup + .dk-optgroup {
    border-top-width: 0;
    margin-top: 0; }
  .dk-optgroup:nth-child(2) {
    padding-top: 0;
    border-top: none;
    margin-top: 0; }
  .dk-optgroup:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0; }

.dk-optgroup-label {
  padding: 0 0.5em 0.25em;
  font-weight: bold;
  width: 100%; }

.dk-optgroup-options {
  list-style: none;
  padding-left: 0; }
  .dk-optgroup-options li {
    padding-left: 1.2em; }

.dk-select-open-up .dk-selected:before, .dk-select-open-down .dk-selected:before {
  transform: rotate(180deg); 
}

.dk-select-open-up .dk-selected:after, .dk-select-open-down .dk-selected:after {
  border-left-color: $color-grey-alabaster; }

.dk-select-open-up .dk-select-options, .dk-select-open-down .dk-select-options, .dk-select-multi:focus .dk-select-options {
  display: block;
  border-color: $color-grey-alabaster; }

.dk-select-multi:hover, .dk-select-multi:focus {
  outline: none; }

.dk-selected:hover, .dk-selected:focus {
  outline: none;
  border-color: $color-grey-alabaster; }
  .dk-selected:hover:before, .dk-selected:focus:before {
    border-top-color: $color-grey-alabaster; }
  .dk-selected:hover:after, .dk-selected:focus:after {
    border-left-color: $color-grey-alabaster; }

.dk-select-disabled {
  opacity: 0.6;
  color: #BBBBBB;
  cursor: not-allowed; }
  .dk-select-disabled .dk-selected:hover, .dk-select-disabled .dk-selected:focus {
    border-color: inherit; }
    .dk-select-disabled .dk-selected:hover:before, .dk-select-disabled .dk-selected:focus:before {
      border-top-color: inherit; }
    .dk-select-disabled .dk-selected:hover:after, .dk-select-disabled .dk-selected:focus:after {
      border-left-color: inherit; }

select[data-dkcacheid] {
  display: none; }





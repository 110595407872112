header {
   position: relative;
   z-index: 100;
}

.c-nav {
   width: 100%;
   height: 130px;
   position: absolute;
   top: 40px;
   left: 0;

   @include mq(medium) {
      top: 30px;
   }
}

.c-nav__ul {
   margin: 50px 0 0 0;
   padding: 0;
   list-style: none;
   float: right;
   font-size: 0;

   @include mq(large) {
      display: none;
   }
}

.c-nav__li {
   margin: 0;
   padding: 0;
   display: inline-block;
   vertical-align: middle;

   &.is-button {
      .c-nav__link {
         color: #fff;
         border: 1px solid #fff;
         padding: 10px 15px 8px 15px;

         &:hover,
         &:focus {
            background: #fff;
            color: $color-grey-outer-space;
         }
      }
   }
}

.c-nav__logo {
   display: inline-block;
   padding: 30px 0 0 0;

   img {
      width: 154px;
      display: inline-block;
   }

   @include mq(large) {
      padding: 0 0 0 15px;

      img:first-of-type {
         width: 125px;
      }
   }
}

.c-nav__lang-toggle {
   display: inline-block;
   margin: 0 0 0 30px;
   position: relative;
   top: 3px;

   a {
      font-family: $font-regular;
      font-style: italic;
      font-size: 14px;
      font-weight: 700;
      text-transform: lowercase;
      color: $color-grey-chalice;
      display: inline-block;
      margin: 0 5px;

      &:first-of-type:after {
         content: '';
         width: 1px;
         height: 15px;
         background: $color-grey-chalice;
         display: inline-block;
         margin-left: 5px;
         position: relative;
         left: 2px;
         top: 3px;
      }

      &.current-lang {
         color: $color-grey-scorpion;
      }
   }
}

.c-nav__link {
   color: $color-grey-chalice;
   font-size: 0.66rem;
   font-family: $font-poppins;
   font-weight: 600;
   text-transform: uppercase;
   letter-spacing: 1px;
   line-height: 1.3;
   display: inline-block;
   padding: 10px 15px;
   position: relative;
   transition: 0.15s linear all;

   &:hover,
   &:focus,
   &:active {
      color: #fff;

      &:after {
         opacity: 1 !important; // don't judge me
      }
   }
}

.has-submenu .c-nav__link:before {
   content: '';
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 0 8.5px 9px 8.5px;
   border-color: transparent transparent #fff transparent;
   position: absolute;
   opacity: 0;
   bottom: -58px;
   left: 50%;
   margin-left: -15px;
   transition: 0.25s linear all;
}

.has-submenu.submenu-active .c-nav__link:before {
   opacity: 1;
   bottom: -48px;
}

.has-submenu .c-nav__link:after,
.has-mobile-submenu .c-mobile-nav__link:after
 {
   content: '';
   display: inline-block;
   width: 9px;
   height: 5px;
   background: url('../images/new/arrow-down-small.png');
   background-size: 100%;
   margin-left: 10px;
   position: relative;
   top: -2px;
   transition: 0.25s linear opacity;
}

.c-nav__button {
   display: inline-block;
   font-size: 1rem;
   color: #fff;
   background: $color-grey-scorpion;
   padding: 12px 40px;
   margin: 0 0 0 30px;
   border-radius: 40px;
   box-shadow: 0px 0px 30px 2px rgba(25,23,25,0.25);
   transition: 0.15s linear all, 0.1s linear top, 0.1s linear box-shadow;

   &:hover,
   &:focus {
      background: $color-grey-scorpion;
      box-shadow: 0px 0px 30px 2px rgba(25,23,25,0);
   }
}

.mobile-menu-toggle {
   position: absolute;
   right: 38px;
   top: 60px;
   display: none;
   border: none;
   background: none;
   cursor: pointer;
   padding: 10px 35px 16px 0px;
   transition: top 0.4s, transform 0.3s;
   z-index: 100;

   span,
   span:before,
   span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 30px;
      background: #fff;
      position: absolute;
      display: block;
      content: '';
      transition: all 0s;
   }

   span {

      &:before {
         top: -10px;
      }

      &:after {
         bottom: -10px;
      }

      // Mobile menu showing up
      .mobile-on & {
         background: transparent;

         &:before{
            top: 0;
            transform: rotate(45deg);
         }

         &:after{
            top: 0;
            transform: rotate(-45deg);
         }
      }
   }

   @include mq(large) {
      display: inline-block;
   }

   @include mq(small) {
      top: 50px;
      right: 25px;
   }
}

.c-mobile-nav {
   width: 300px;
   height: 100vh;
   position: fixed;
   left: 0;
   top: 0;
   transform: translate(-300px, 0);
   background: $color-black;
   box-shadow: 0 0 40px 2px rgba(25, 23, 25, 0);
   transition: 0.3s ease transform;

   li {
      width: 100%;
      margin: 0;
      padding: 0;
   }

   .mobile-on & {
      transform: translate(0, 0);
      box-shadow: 0 0 40px 2px rgba(25, 23, 25, 0.45);
   }

}

.c-mobile-nav .c-nav-grey__social {
   float: none;
   display: inline-block;
}

.c-mobile-nav__list {
   margin: 0;
   padding: 0;
   list-style: none;
}

.c-nav__subnav {
   width: 100%;
   font-family: $font-poppins;
   font-size: 1rem;
    max-width: 1100px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 140px;
    transform: translate(-50%,0);
   opacity: 0;
   background: rgba(0, 0, 0, 0.8);
   pointer-events: none;
   transition: 0.25s linear all;

   ul {
      float: none;
      overflow: hidden;
      width: 100%;
      clear: both;
      padding: 30px 0 0 0;
      border-top: 1px solid #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
   }

   li {
      margin: 0;
      text-align: left;
      width: 25%;

      &:nth-child(4n) {
         padding-right: 0;
      }
   }

   .submenu-active & {
      opacity: 1;
      top: 130px;
      pointer-events: auto;
   }
}

.c-nav__subnav-content {
   padding: 40px;
   border: 1px solid #fff;
}

.c-nav__subnav-content .c-button--white {
   position: relative;
   top: -10px;
   float: right;
}

.c-nav__subnav-title {
   color: #fff;
   font-size: 1.5rem;
   line-height: 1.8rem;
   float: left;
   margin: 0 0 30px 0;
   text-align: left;
}

.c-nav__subnav-link {
   color: #fff;
   padding: 10px 40px 20px 10px;
   display: inline-block;
   width: 100%;
   cursor: pointer;
   transition: 0.15s linear color;
   text-align: center;

   &:hover,
   &:focus {
      text-decoration: underline;
   }
}

.c-nav__subnav-content li a {
   @extend .c-nav__subnav-link;
}

.c-mobile-nav__link {
   font-family: $font-poppins;
   font-size: 13px;
   width: 100%;
   padding: 15px 20px;
   color: #fff;
   display: inline-block;
   text-align: center;
   transition: 0.15s linear background;

   &:hover,
   &:focus {
      color: $color-black;
      background: #fff;
   }
}

.c-mobile-nav__grey-link {
   @extend .c-mobile-nav__link;
}

.has-mobile-submenu {

   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      max-height: 0;
      overflow: hidden;
      background: #d8d8d8;
   }

   li {
      margin: 0;
      padding: 0;
   }

   &.subnav-opened ul{
      max-height: 5000px;
   }
}

.c-mobile-nav_subnav-link {
   width: 100%;
   padding: 15px 20px 15px 30px;
   color: $color-black;
   display: inline-block;
   text-align: center;
   transition: 0.15s linear background;

   &:hover,
   &:focus {
      background: #c3c3c3;
   }
}

.c-mobile-nav__phone {
   font-size: 1.25rem;
   font-family: $font-poppins;
   color: #fff;
   padding: 15px 20px;
   display: block;
   text-align: center;

   i {
      display: inline-block;
      margin-right: 5px;
      color: #fff;
   }
}

.single-inscription {
   .c-nav__link {
      color: white;
   }
}
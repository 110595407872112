.c-presentation {
   img {
      display: block;
      margin: auto;
      max-width: 500px;
   }
}

.c-presentation__name {
   font-size: 3.125rem;
   color: $color-black;
   font-weight: 400;
   display: block;

   @include mq(large) {
      font-size: 2rem;
   }

   @include mq(small) {
      font-size: 1.5rem;
   }
}

.c-presentation__title {
   font-size: 1.5rem;
   color: $color-grey-scorpion;
   font-weight: 400;
   display: block;

   @include mq(large) {
      font-size: 1rem;
   }
}

.c-presentation__person {
   max-width: 50%;
   padding-top: 90px;

   &:first-of-type {
      float: left;
   }

   &:last-of-type {
      float: right;
      text-align: right;
   }

   @include mq(medium) {
      padding-top: 30px;
      min-height: 650px;
   }
}
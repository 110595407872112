.c-membre {
   background-color: #f9f7f8;
   background-image: url('../images/background-logo.png');
   background-repeat: no-repeat;
   background-position: center center;
   padding: 150px 0;
   position: relative;

   @include mq(large) {
      padding: 60px 0;
   }
}

.c-membre-content {
   max-width: 60%;
   position: relative;
   z-index: 2;

   @include mq(medium) {
      max-width: none;
      clear: both;
   }
}

.c-membre__name {
   color: $color-black;
   font-weight: 900;
   font-size: 4.6875rem;
   line-height: 1;
   margin: 0;

   @include mq(large) {
      font-size: 2.8rem;
   }
}

.c-membre__job {
   color: $color-grey-scorpion;
   font-size: 1.5rem;
   font-weight: 400;
}

.c-membre__about {
   font-size: 2.8125rem;
   font-weight: 400;
   margin: 2rem 0;

   @include mq(large) {
      font-size: 1.6rem;
   }
}

.c-membre__img {
   position: absolute;
   bottom: 0;
   right: 0;
   max-height: 90%;

   @include mq(xxl) {
      max-height: 75%;
      max-width: 480px;
   }

   @include mq(large) {
      max-width: 350px;
   }

   @include mq(medium) {
      display: none;
   }
}

.c-membre p {
   color: $color-grey-light;
   font-size: 0.9375rem;
   font-weight: 400;
   line-height: 1.8;
   margin-bottom: 2.5rem;
}

.c-membre .c-button,
.c-membre .c-button--black {
   margin: 0 20px 20px 0;
}
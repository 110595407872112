.c-sidebar-property {
    width: 300px;
    float: right;
    padding: 0 0 30px 30px;

    @include mq(medium) {
        width: 100%;
        margin: 30px 0;
        padding: 0;
    }
}

.c-sidebar-property__subtitle {
    font-size: 1.25rem;
    font-weight: 700;
    color: $color-black;
    margin: 25px 0 10px 0;

    &:first-of-type {
        margin-top: 0;
    }
}

.c-sidebar-property__info {
    font-size: 0.875rem;
    font-weight: 400;
    display: block;
    color: $color-grey-medium;
    margin: 0 0 10px 0;

    i {
        color: $color-grey-scorpion;
        display: inline-block;
        margin-left: 5px;
    }
}

.c-sidebar-property__caracteristics {

    img {
        width: 28px;
        margin-right: 7px;
        display: inline-block;
    }

}

.c-sidebar-property__courtier {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #ebedef;
    margin: 30px 0 5px 0;
    background-size: cover;

    &:first-of-type {
        margin-top: 10px;
    }
}

.c-sidebar-property__name {
    display: block;
    font-size: 1.5rem;
    color: $color-grey-medium;
    font-size: 400;

    span {
        font-weight: 700;
        color: $color-black;
    }
}

.c-sidebar-property__poste {
    display: block;
    font-size: 0.875rem;
    color: $color-grey-medium;
}

.c-sidebar-property .c-button {
    margin: 10px 0;
}

/* =======================================================================
Helpers
========================================================================== */

.h-filtered-number {
	float: left;
}

.h-centered {
	text-align: center;
}

.h-flex {
	display: flex;
	flex-wrap: wrap;
}

.h-flex--nowrap {
	display: flex;
	align-items: center;
}

.h-relative {
	position: relative;
}

.h-overflow {
	overflow: hidden;
}

.h-top-spacing {
	margin-top: 40px;

	&--large {
		margin-top: 60px;
	}
}

.h-stacked-mobile {
	@include mq(small) {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
	}
}

.h-bottom-spacing {
	margin-bottom: 40px !important;

	&--large {
		margin-bottom: 60px !important;
	}
}

.h-google-map {
	width: 50%;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	z-index: -1;

	@include mq(medium) {
		display: none;
	}
}

.h-full-width {
	width: 100%;
}

.h-push-bottom {
	margin-bottom: 20px;
}

.h-no-margin {
	margin: 0;
}

.h-button-spacing {
	margin: 40px 0;
}

.h-subtitle {
	font-size: 1.5rem;
    font-weight: 400;
    color: $color-grey-light;
    margin: 0 0 1rem 0;
}

.h-hide-mobile {
	@include mq(medium) {
		display: none;
	}
}

.h-black-gradient {
	background: linear-gradient(to bottom, rgb(12, 13, 13) 0%, rgba(34,35,35,1) 100%);
}

.h-title-two-lines {
	line-height: 1.2;

	span {
		display: block;
		color: $color-blue;
		font-size: 2.25rem;
		font-weight: 700;
	}

	&.is-red span {
		color: #ea155f;
	}
}

.h-border-gap  {
	display: block;
	width: 100%;
	height: 1px;
	margin: 80px 0;
}

.h-bg-grey {
	background: #fcfcfc;
}

.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.h-embed-responsive .h-embed-responsive-item,
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}


.h-video-background {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;

	&:before {
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: rgba(0,0,0,0.4);
		content: "";
		display: block;
		z-index: 1;
	}
}

.h-video-foreground,
.h-video-background iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.h-video-foreground {
	height: 300% !important;
	top: -100% !important;
}

@media (min-aspect-ratio: 16/9) {
	.h-video-foreground {
  		height: 300% !important;
		top: -100% !important;
   	}
}

@include mq(large) {
	.h-video-background iframe {
		width: 120%;
	}
}

@include mq(medium) {
	.h-video-foreground {
		width: 300% !important;
		left: -100% !important;
	}
}
.c-contact-details {
   @include span(isolate 4 at 1);
   background: $color-black;
   min-height: 500px;
   padding: 30px;
   border-bottom: 20px solid $color-grey-scorpion;
   margin-left: 40px;
   box-shadow: 0px 0px 40px 2px rgba(25,23,25,0.3);

   @include mq(large) {
      width: 100%;
      min-height: auto;
      margin: 40px 0 0 0;
      float: none;
   }
}

.c-contact-details h4 {
   font-size: 1.5rem;
   font-weight: 400;
   color: #fff;
   margin-bottom: 10px;

   &:first-of-type {
      margin-top: 0;
   }
}

.c-contact-details h5 {
   font-size: 0.9375rem;
   font-weight: 700;
   color: $color-grey-scorpion;
   margin-bottom: 0;
}

.c-contact-details a {
   font-size: 2.25rem;
   font-weight: 700;
   color: #fff;
}

.c-contact-details .c-contact-details__email {
   font-size: 1.5rem;
}
.c-infos-supplementaires {
	text-align: center;
	ul {
		margin: 60px 0 40px 0;
		padding: 0;
		list-style: none;
		text-align: center;
	}

	li {
		display: inline-block;
		margin-right: 20px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	dl {
		display: inline-block;
		width: 225px;
		vertical-align: top;
		margin: 0 30px;
	}

	@include mq(medium) {
		dl {
			width: 100%;
			margin: 0;
		}
	}
}

.c-infos-supplementaires__title {
	font-family: $font-poppins;
	font-size: 1rem;
	letter-spacing: 1px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: left;
}

.c-infos-supplementaires__stat {
	font-family: $font-butler;
	color: #727272;
	font-size: 1.8rem;
	letter-spacing: 3px;
	display: inline-block;
	margin-left: 5px;
}

.c-infos-supplementaires__small-title {
	@extend .c-infos-supplementaires__title;
	text-transform: none;
	margin-top: 30px;
	text-align: left;
}

.c-infos-supplementaires__small-stat {
	@extend .c-infos-supplementaires__stat;
	display: block;
	font-size: 0.9375rem;	
	margin: 10px 0 0 0;
	text-align: left;
}
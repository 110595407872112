.c-cta {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin: 100px 0;
 }

 .c-cta__single {
    width: calc(33% - 6px);
    margin: 0 3px;
    background-size: cover;
    background-position: center;
    padding: 25px;
    min-height: 325px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    flex: auto;
    background: #dedede;
    background-size: cover;
    background-position: center;

    &:before {
       content: '';
       width: 100%;
       height: 100%;
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       background: rgba(31, 31, 31, 0.7);
       transition: 0.35s ease all;
    }

    &:after {
       content: '';
       width: 100%;
       height: 100%;
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.64) 99%,rgba(0,0,0,0.65) 100%);
       transition: 0.35s ease all;
    }

    &:hover,
    &:focus {
       &:before {
          background: rgba(31, 31, 31, 0.9);
       }
    }

    @include mq(large) {
       width: calc(50% - 6px);
       margin-bottom: 15px;

       .c-cta__excerpt {
          opacity: 1;
          max-height: 200px;
       }

       &:hover,
       &:focus {
          &:before {
             background: rgba(31, 31, 31, 0.7);
          }
       }
    }

    @include mq(medium) {
       width: 100%;
       margin-bottom: 15px;

       .c-cta__excerpt {
          opacity: 1;
          max-height: 200px;
       }

       &:hover,
       &:focus {
          &:before {
             background: rgba(31, 31, 31, 0.7);
          }
       }
    }
 }

 .js-cta-1 {
    opacity: 0;
    transform: translate(0, -700px);

    @include mq(medium) {
       transform: translate(0, 0);
    }
 }

 .js-cta-2 {
    opacity: 0;
    transform: translate(0, -500px);

    @include mq(medium) {
       transform: translate(0, 0);
    }
 }

 .js-cta-3 {
    opacity: 0;
    transform: translate(0, -900px);

    @include mq(medium) {
       transform: translate(0, 0);
    }
 }

 .c-cta__content {
    z-index: 2;
 }

 .c-cta__title {
    font-size: 22px;
    line-height: 28px;
    font-family: $font-poppins;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.1;
    color: $color-grey-mercury;
    font-weight: 600;
    margin-bottom: 0;

    span {
       font-family: $font-butler-light;
       font-weight: 100;
       color: lighten($color-grey-scorpion, 40);
       font-size: 20px;
       display: block;
    }

    &.is-small {
       font-size: 16px;
       line-height: 1.3;
    }

    &.is-dark {
       color: $color-outer-space;

       span {
          color: $color-grey-scorpion;
       }
    }
 }

 .c-cta__excerpt {
    color: $color-grey-dim;
    font-size: 14px;
    line-height: 1.5;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.6s ease-out all;

    p {
       margin: 0;
       padding: 30px 0 20px 0;
    }
 }

 .c-cta__button {
    color: $color-grey-dim;
    font-size: 16px;
    margin-top: 10px;
    letter-spacing: 1px;
    font-style: italic;
    display: inline-block;

    &:after {
       content: '+';
       color: $color-grey-scorpion;;
       display: inline-block;
       margin-left: 7px;
       font-family: $font-butler;
       font-style: normal;
    }
 }

 .c-cta__single:hover,
 .c-cta__single:focus {

    .c-cta__excerpt {
       max-height: 200px;
       opacity: 1;
       transition: 1s ease all;
    }
 }
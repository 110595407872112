.c-blog-summary {
   background: $color-grey-gallery;
   overflow: hidden;

   .section-title {
      margin: 0 0 50px 0;
   }
}

.c-blog-summary__single {
   @include span(3);
   background: #fff;
   padding: 25px 30px 45px 30px;
   margin-top: 20px;
   overflow: hidden;
   position: relative;

   p:empty {
      display: none;
   }

   &:nth-child(1),
   &:nth-child(2),
   &:nth-child(3),
   &:nth-child(4) {
      margin-top: 0;
   }

   &:hover,
   &:focus {
      .c-blog-summary__button {
         transform: translate(0, 0);
      }
   }

   @include mq(medium) {
      @include span(6);
      margin-bottom: 10px;

      &:last-of-type {
         margin-bottom: 0;
      }
   }

   @include mq(small) {
      @include span(12);

      .c-blog-summary__button {
         transform: translate(0, 0);
      }
   }
}

.c-blog-summary__date {
   font-family: $font-butler;
   font-size: 14px;
   color: $color-grey-scorpion;
}

.c-blog-summary__title {
   font-size: 16px;
   font-weight: 600;
   font-family: $font-poppins;
   text-transform: uppercase;
   color: $color-grey-outer-space;
   margin-top: 10px;
}

.c-blog-summary__excerpt {
   color: $color-grey-dim;
}

.c-blog-summary__button {
   color: $color-grey-dim;
   background: $color-grey-outer-space;
   font-size: 18px;
   font-family: $font-butler;
   letter-spacing: 1px;
   font-style: italic;
   padding: 13px 10px;
   display: block;
   text-align: center;
   transform: translateY(calc(100% - 5px));
   // margin-top: 25px;
   position: absolute;
   width: calc(100% - 60px);
   bottom: 0;
   left: 30px;
   transition: 0.35s ease all;

   &:after {
      content: '+';
      color: #fff;
      display: inline-block;
      margin-left: 10px;
      font-family: $font-butler;
      font-style: normal;
   }

   &:hover,
   &:focus {
      color: #fff;
      background: $color-grey-cod;
   }
}


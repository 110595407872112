.c-filter__container {
   margin-left: -10px;
   margin-top: 30px;

   @include mq(medium) {
      margin-left: 0px;
   }
}

.c-filter-button {
   background: $color-grey-lightish;
   padding: 40px 0 30px 0;
   text-align: center;  
   overflow: hidden;
   border-bottom: 1px solid #d9d9da;
}

.c-filter-button__single {
   @extend .c-button;
   margin: 0 10px 10px 10px;
   background: transparent;
   color: $color-black;
   border: 2px solid $color-grey-scorpion;

   &:hover,
   &:focus,
   &.active {
      background: $color-grey-scorpion;
      color: #fff;
   }
}

.c-filter-button--with-title {
   @extend .c-filter-button;
   text-align: left;
   border: none;
}

.c-filter-button--with-title h3 { 
   display: inline-block;
   margin: 0 40px 0 0;
   font-size: 1.875rem;
   font-weight: 400;

   @include mq(medium) {
      display: block;
      margin-bottom: 20px;
   }
}

.c-filter-button--with-title .c-filter-button__single {
   @include mq(medium) {
      margin: 0 20px 20px 0;
   }
}
/* =======================================================================
Footer
========================================================================== */

.c-footer footer {
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(15,15,15,1) 100%);
    padding: 80px 0;
    overflow: hidden;
    width: 100%;

    time {
       font-size: 0.8125rem;
       color: $color-grey-scorpion;
    }

    p {
       font-size: 0.75rem;
       margin: 1rem 0 2.2rem;
       color: #fff;
    }

    .l-row {
       margin: 0 calc( 3.125% * -1 );
    }

    @include mq(medium) {
       padding: 30px 0;
    }
 }

 .c-footer .c-button {
    padding: 8px 30px;
    text-align: left;
 }

 .c-footer__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0;
    color: #767676;
 }

 .c-footer__publications {
    @include mq(medium) {
       display: none;
    }
 }

 .c-footer__publications__single {
    @include susy-breakpoint(min-width 0px, (columns: 4) (gutters: 1px/3px)) {
       @include span(1);
    }
 }

 .c-footer__publications__single h5 {
    margin: 0;
    font-size: 0.9375rem;
    color: #fff;
 }

 .c-footer__menu {
    overflow: hidden;
    margin-bottom: 60px;

    @include mq(medium) {
       text-align: center;
       border-top: none;
       margin: 0;
       padding: 20px 0;
    }
 }

 .c-footer__logo {
    width: 18.75%;
    margin-right: 7.75%;
    float: left;

    @include mq(medium) {
       float: none;
       display: block;
       width: 100%;
       margin: 0 0 60px 0;
       text-align: center;
    }
 }

 .c-footer__logo img {
    width: 170px;
 }

 .c-footer__menu-list {
    float: left;
    margin-right: 9.25%;
    width: 18.33333%;

    &:last-of-type {
       margin-right: 0;
    }

    @include mq(large) {
       margin-right: 5%;

       &:last-of-type {
          margin-right: 0;
       }
    }

    @include mq(medium) {
       width: 100%;
       margin: 0 0 30px 0;
       float: none;
       display: inline-block;
       vertical-align: top;
       text-align: left;
    }
 }

 .c-footer__menu-list h5 {
    font-family: $font-butler;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 10px 0;
    color: $color-grey-alabaster;
 }

 .c-footer__menu-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
 }

 .c-footer__menu-list a {
    color: $color-grey-dim;
    font-size: 0.6875rem;
    font-family: $font-poppins;
    font-weight: 600;
    line-height: 1.8;
    letter-spacing: 0.5px;
     margin-bottom: 3px;
    text-transform: uppercase;
    transition: 0.15s linear color;
    display: inline-block;

    &:hover,
    &:focus {
       color: #fff;
    }
 }

 .c-footer__contact-section {
    float: left;
    margin-right: 9.25%;
     width: 18.33333%;

    &:first-of-type {
       margin-left: 26.5%;
    }

    @include mq(medium) {
       width: 100%;
       margin: 0;

       &:first-of-type {
          margin: 0;
       }
    }
 }

 .c-footer__number-title {
    font-family: $font-butler;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 10px 0;
    display: block;
    color: $color-grey-alabaster;
 }

 .c-footer__number {
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 1rem;
    color: $color-grey-scorpion;
    display: inline-block;

    &:hover,
    &:focus {
       text-decoration: underline;
    }

    @include mq(medium) {
       margin-bottom: 30px;
    }
 }

 .c-footer__contact-section:last-of-type .c-footer__number {
    @include mq(medium) {
       margin-bottom: 0;
    }
 }

 .c-footer__social {
    @include mq(large) {
       width: 100%;
       overflow: hidden;
    }
 }

 .c-footer__social__link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $color-grey-light;
    background: transparent;
    float: left;
    margin-right: 20px;
    display: flex;
     align-items: center;
     justify-content: center;
     transition: 0.15s linear background;

    i {
       font-size: 0.9375rem;
       color: #fff;
       text-align: center;
       display: inline-block;
       transition: 0.15s linear color;
    }

    &:hover,
    &:focus {
       background: $color-grey-scorpion;
       border: 2px solid $color-grey-scorpion;

       i {
          color: #fff;
       }
    }
 }

 .c-button__write-us {
    float: right;

    @include mq(small) {
       float: left;
       margin-bottom: 30px;
    }
 }

 .c-footer__legal {
    overflow: hidden;
    width: 100%;
    padding: 10px 0;
    background: $color-grey-cod;

    .c-nav-grey__link {
       line-height: normal;
    }

    @include mq(medium) {
       padding: 20px 0;
       text-align: center;

       .c-nav-grey__menu {
          text-align: center;
          display: inline-block;
          float: none;

          li {
             margin: 0 10px;
          }
       }

       .c-nav-grey__link {
          padding: 0;
       }
    }
 }

 .c-footer__author {
    font-family: $font-poppins;
    text-transform: uppercase;
    font-size: 8.5px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $color-grey-dove;

    a {
       color: $color-grey-scorpion;

       transition: 0.3s;

       &:hover,
       &:focus {
          color: #fff;
       }

       img{
          width: 24px;
          height: 24px;
          margin: 0 0 2px 5px;
       }
    }
 }



.c-stats {
	margin: 60px 0;
}

.c-stats__single {
	margin-bottom: 2rem;
	text-align: center;
	width: 75%;
	margin-right: auto;
	margin-left: auto;

	&:last-child {
		margin-bottom: 0;
	}

	@include mq(small) {
		margin-bottom: 1rem;
		width: 95%;
	}
}

.c-stats img {
	display: inline-block;
}

.c-stats h3 {
	font-size: 1.625rem;
	line-height: 1.8rem;
	margin-bottom: 10px;
	font-weight: 700;
}

.c-stats p {
	font-size: 1.1875rem;
	color: $color-grey-light;
}
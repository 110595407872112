.c-button {
	display: inline-block;
	font-family: $font-poppins;
	color: #fff;
	font-weight: 600;
	font-size: 10.5px;
	background: $color-outer-space;
	padding: 8px 20px 6px 20px;
	letter-spacing: 1px;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 500;
	border: 1px solid $color-outer-space;
	transition: 0.35s ease-out all;

	&.has-shadow {
		box-shadow: 0px 0px 40px 2px rgba(25,23,25,0.3);
	}

	&:hover,
	&:focus {
		background: #fff;
		color: $color-outer-space;
	}
}

.c-button--white {
	@extend .c-button;
	background: #fff;
	color: $color-outer-space;

	&:hover,
	&:focus,
	&.active {
		background: $color-outer-space;
		color: #fff;
		border-color: #fff;

		&.keep-border {
			border-color: $color-outer-space;
		}
	}
}

.c-button--transparent {
	@extend .c-button;
	background: transparent;
	color: #fff;
}

.c-button--large-black {
	color: #fff;
	background: $color-grey-woodsmoke;
	font-size: 18px;
	margin-top: 10px;
	letter-spacing: 1px;
	font-style: italic;
	padding: 20px 40px;
	z-index: 3;
	transition: 0.35s ease-out all;

	&:hover,
	&:focus {
		color: $color-grey-woodsmoke;
		background: #fff;
	}
}

.c-button-wrapper {
	text-align: center;
	font-size: 0;
	position: relative;
	padding-bottom: 70px;

	a:last-of-type {
		position: relative;
		left: -1px;
	}

	a:first-of-type {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			bottom: -70px;
			right: 0;
			width: 1px;
			height: 15px;
			background: $color-outer-space;
		}

		&.no-after {
			&:after {
				content: none;
			}
		}
	}

	&.no-decorative-border a:after {
		display: none;
	}

	.c-button--white {
		&:hover,
		&:focus {
			border-color: $color-outer-space;
			border-left: 1px solid #fff;
		}
	}

	@include mq(small) {
		a {
			margin-bottom: 10px;

			&:first-of-type:after {
				display: none;
			}
		}
	}
}
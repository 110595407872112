/* =======================================================================
Forms
========================================================================== */

$color-input: #e3e3e3;
$color-background: #151717;

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
textarea {
	width: 100%;
	display: block;
	margin-bottom: 20px;
	background: transparent;
	border: 1px solid $color-input;
	text-transform: uppercase;
	color: $color-input;
	font-family: $font-poppins;
	font-size: 11.5px;
	padding: 15px 20px;
	transition: all 0.25s linear;

	&::-webkit-input-placeholder {
		transition: all 0.25s linear;
	}

	&:hover,
	&:focus {
		background: white;
		color: $color-background;

		&::-webkit-input-placeholder {
			color: $color-background;
		}
	}
}



.form-wrapper {
	max-width: 600px;
	margin: 0 auto;

	h2 {
		font-family: $font-poppins;
		font-size: 1.5625rem;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
		color: $color-grey-mercury;
		margin: 0;
	}

	p {
		font-family: $font-butler;
		font-size: 1rem;
		text-transform: uppercase;
		color: $color-blue-light-cyan;
		margin: 10px 0 30px 0;
	}

	input[type="text"],
	input[type="number"],
    input[type="tel"],
	input[type="email"] {
		width: calc(50% - 16px);
		margin: 0 0 16px;
		float: left;
		padding: 20px;
    }

	.wpcf7-form-control-wrap {
		display: block;
		width: 100%;
		float: left;

		&.your-message {
			width: 100%;
			margin-bottom: 16px;
		}
	}

	.wpcf7-form-control-wrap input[type="text"],
    .wpcf7-form-control-wrap input[type="number"],
	.wpcf7-form-control-wrap input[type="tel"],
	.wpcf7-form-control-wrap input[type="email"] {
		width: 100%;
	}

    .wpcf7-validation-errors {
        display: none !important;
    }
    span.wpcf7-not-valid-tip {
        font-size: 12px;
        font-family: $font-poppins;
        margin: 0 8px 16px
    }

    div.wpcf7-mail-sent-ng {
        border: none;
        font-size: 12px;
        font-family: $font-poppins;
        color: red;
        margin: 0px;
        padding: 0px 8px;
    }

    div.wpcf7-mail-sent-ok {
        border: none;
        font-size: 12px;
        font-family: $font-poppins;
        color: #398f14;
        margin: 0px;
        padding: 0px 8px;
    }

    textarea {
        width: calc(100% - 16px);
        margin: 0 8px;
        padding: 20px;
    }

    button {
        display: inline-block;
        background: transparent;
        border: 1px solid $color-input;
        text-transform: uppercase;
        color: $color-input;
        font-family: $font-poppins;
        font-size: 11.5px;
        padding: 10px 15px;
        float: right;
        margin-right: 8px;
        transition: 0.25s ease all;

        &:hover,
        &:focus {
            color: $color-background;
            background: $color-input;
        }
    }

    @include mq(medium) {
        max-width: none;
    }
}

.c-home-eval {
    .wpcf7-form-control-wrap {
        input[type=email],
        input[type=number],
        input[type=tel],
        input[type=text],
        textarea,
        select {
            font-family: $font-poppins;
        }

        select {
            height: 45px;
            border: 1px solid #e3e3e3;
            color: $color-background;
            padding: 10px 20px;
            text-transform: uppercase;
            font-size: 11.5px;
        }
    }

    // Placeholder color
    ::-webkit-input-placeholder { /* WebKit browsers */
        color: $color-background;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color-background;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color-background;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color: $color-background;
    }
}
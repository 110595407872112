.c-search-results-bar {
   background: $color-grey-dark;
   padding: 0px 0px;

   .l-page-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   span {
      color: $color-grey-medium;
      font-weight: bold;
      padding: 0px 5px;
   }

   p,
   a {
      font-size: 16px;
   }

   a {
      color: white;
      transition: all 200ms ease;

      i {
         color: $color-grey-scorpion;
      }

      &:hover,
      &:focus {
         color: $color-grey-scorpion;
      }
   }

   p {
      color: white;

      i {
         color: $color-grey-scorpion;
      }
   }

   strong {
      color: white;
   }
}
.c-famille {

   @include mq(medium) {
      display: none;
   }
}

.c-famille h2 {
   font-size: 3.125rem;
   display: inline-block;
   float: left;

   span {
      color: $color-grey-scorpion;
   }

   @include mq(large) {
      display: block;
      float: none;
      margin-bottom: 20px;
   }
}

.c-famille .c-button--black {
   float: right;
   margin-top: 50px;

   @include mq(large) {
      float: none;
      margin: 0 0 20px 0;
   }
}

.c-famille__image {
   width: 100%;
   display: block;
   margin: 0 auto;
}
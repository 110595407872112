.c-why-banner {
    background: black;
    min-height: 700px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-align: center;

    @media (max-width: 480px) {
        padding: 40px 20px;
        min-height: 600px;
    }

    h1 {
        font-family: $font-butler;
        margin-top: 30px;
        font-weight: 300;
        text-transform: initial;
        margin-bottom: 0px;
        color: white;

        @media (max-width: 1024px) {
            font-size: 40px;
            line-height: 50px;
        }

        @media (max-width: 480px) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    p {
        font-family: $font-poppins;
        font-size: 16px;
        line-height: 22px;
        color: white;
        font-weight: 300;
        letter-spacing: 1px;
        margin-bottom: 40px;

        @media (max-width: 480px) {
            font-size: 11px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }

    .button--bordered {
        color: white;
        border: 1px solid white;
        padding: 12px 20px;
        font-family: $font-poppins;
        font-size: 14px;
        line-height: 20px;
        margin: 0 5px;
        transition: all 300ms ease;
        display: inline-block;

        @media (max-width: 650px) {
            display: block;
            width: 100%;
            text-align: center;
            margin: 10px 0;
        }

        @media (max-width: 480px) {
            font-size: 12px;
        }

        &:hover {
            background: white;
            color: black;
        }
    }

    .h-centered {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @media (max-width: 650px) {
            flex-flow: column wrap;
        }
    }
}

.c-why-content {

    h2 {
        font-family: $font-butler;
        font-weight: 300;
        font-size: 40px;
        line-height: 50px;
    }

    .c-stats h3 {
        font-family: $font-butler;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        max-width: 220px;
        margin-left: auto;
        margin-right: auto;

        & + p {
            font-family: $font-poppins;
            font-size: 18px;
            line-height: 26px;
            font-weight: 300;
        }
    }

    .button-black {
        background: black;
        color: white;
        padding: 15px 25px;
        font-size: 15px;
        line-height: 20px;
        display: inline-block;
    }
}

.c-why-facts.c-pourquoi p {
    font-family: $font-butler;
    font-size: 36px;
    line-height: 42px;
    color: white;
    font-weight: 300;

    strong {
        display: block;
    }
}
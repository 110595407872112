.c-partenaires-container {
	display: flex;
	flex-wrap: wrap;
}

.c-partenaire {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	@include span(4);

	&--smaller {
		@include span(3);
	}

	@include mq(medium) {
		@include span(6);
		margin-bottom: 30px;

		&--smaller {
			@include span(6);
		}
	}

	@include mq(small) {
		width: 100%;

		&--smaller {
			width: 100%;
		}
	}
}

.c-partenaire h2 {
	font-family: $font-poppins;
	font-size: 1.8rem;
	line-height: 2.25rem;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

.c-partenaire__logo {
	background-size: cover;
	background-position: 50% 50%;
	height: 250px;
	width: 250px;
	display: block;
	border-radius: 100%;
	margin: auto auto 0px auto;
	
	@include mq(small) {
		margin-bottom: 30px;
		width: 200px;
		height: 200px;
	}
}
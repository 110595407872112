.c-equipe {
   margin: 40px 0 60px 0;
}

.c-equipe__single {
   @include span(4);
   min-height: 350px;
   margin-bottom: 20px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   border: 1px solid #ebedef;
   position: relative;
   box-shadow: 0px 0px 0px 2px rgba(25,23,25,0);
   overflow: hidden;
   transition: 0.15s linear all;

   &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg,rgba(0, 0, 0, 0.9) 0,rgba(0, 161, 255, 0.65));
      opacity: 0;
      transition: 0.25s ease-out all;
   }

   @include mq(medium) {
      @include span(6);
   }

   @include mq(small) {
      width: 100%;
   }
}

.c-equipe__single:hover,
.c-equipe__single:focus {

   &:before {
      opacity: 1;
   }
   .c-equipe__single-content {
      transform: translate(0, 0);
   }

   .c-equipe__single-name:after {
      opacity: 1;
   }
}

.c-equipe__single-content {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   padding: 20px;
   transform: translate(0, 50px);
   transition: 0.25s linear all;
}

.c-equipe__single-name {
   font-size: 1.5rem;
   line-height: 1;
   color: #fff;
   font-weight: 400;

   &:after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      margin-top: 10px;
      margin-bottom: 10px;
      background: $color-grey-scorpion;
      opacity: 0;
      transition: 0.25s linear all;
   }
}

.c-equipe__single-name span {
   font-size: 2.25rem;
   color: $color-grey-scorpion;
   font-weight: 700;
   display: block;
}

.c-equipe__single-more {
   font-size: 1.0625rem;
   font-weight: 100;
   color: #fff;
}

.c-equipe__single-more i {
   display: inline-block;
   margin-left: 5px;
}
.c-list-acheteurs__title {
   display: inline-block;

   @include mq(small) {
      display: block;
      margin-bottom: 0;
   }
}

.c-list-acheteurs__single {
   @include span(6);
   min-height: 300px;
   margin-bottom: gutter(6);
   background: #dedede;
   position: relative;
   background-position: center;
   background-size: cover;

   &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.4);
      transition: 0.25s linear background;
   }

   @include mq(small) {
      width: 100%;
      margin-bottom: 15px;
   }
}

.c-list-acheteurs__single-content {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   padding: 25px 210px 25px 25px;
   overflow: hidden;

   @include mq(medium) {
      padding: 25px;
   }
}

.c-list-acheteurs__single-special {
   display: block;
   font-size: 0.875rem;
   color: $color-grey-scorpion;
}

.c-list-acheteurs__single-title {
   display: block;
   font-size: 1.25rem;
   color: #fff;
   margin-top: 0px;

   &:after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      margin-top: 10px;
      background: $color-grey-scorpion;
   }
}

.c-list-acheteurs__single-button {
   @extend .c-button;
   position: absolute;
   right: 25px;
   top: 50%;
   margin-top: -20px;
   transform: translate(0, 50px);
   opacity: 0;
   transition: 0.25s linear all;

   @include mq(medium) {
      position: static;
      margin-top: 15px;
      opacity: 1;
      transform: translate(0, 0);
   }
}

.c-list-acheteurs__single:hover,
.c-list-acheteurs__single:focus,
.c-list-acheteurs__single:active {

   &:before {
      background: rgba(35, 31, 32, 0.8);
   }

   .c-list-acheteurs__single-button {
      opacity: 1;
      transform: translate(0, 0);
   }  
}
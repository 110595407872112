/* =======================================================================
Icons
========================================================================== */

.i-arrow-right {
	display: inline-block;

	&:after {
		content: '';
		width: 20px;
		height: 12px;
		background: url(../images/new/arrow-right.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		margin-left: 10px;
		display: inline-block;
	}
}
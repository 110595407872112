$color-contact-bg: #eeefef;

.phone-link {
	font-family: $font-butler;
    color: #b4c3c7;
	font-size: 1.2rem;
	line-height: 1.6rem;
    margin-bottom: 20px;
    display: block;
}

.follow-us-link {
	color: #b4c3c7;
	margin: 30px 0;
	display: block;
	transition: all 300ms ease;

	&:hover {
		color: white;
	}

	i {
		color: white;
	}
}

.c-contact {
	display: flex;
	position: relative;

	&:before {
		content: '';
		width: 50%;
		height: 100%;
		background: $color-contact-bg;
		left: 0;
		top: 0;
		position: absolute;
		z-index: -1;
	}

	@include mq(medium) {
		display: block;

		&:before {
			width: 100%;
		}
	}
}

.c-contact__left {
	z-index: 2;
	padding: 100px 0;

	div {
		max-width: 520px;
		padding-left: 180px;
	}

	h2 {
		font-family: $font-poppins;
		font-size: 2.5rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		padding-left: 100px;
		margin-bottom: 50px;
	}

	p {
		font-family: $font-poppins;
		font-size: 1rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		line-height: 2;
		margin-bottom: 40px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	a {
		color: $color-grey-dim;
		display: block;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	@include mq(medium) {
		padding: 40px 0;

		div {
			max-width: none;
			padding-left: 0;
		}

		h2 {
			padding-left: 0;
			font-size: 1.8rem;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 20px;
		}
	}
}

// Wordpress form overwrites

.wpcf7-form-control-wrap {
    position: relative;
    overflow: hidden;
    display: block;
    float: left;
    width: 47%;
    margin: 8px 1%;

    &.autresInformationsPertinentes {
    	width:96%;
    }

    input,
    textarea {
    	width: 100%;
    	margin: 0 0 10px 0;
    }

    select {
    	appearance:none;
    	border-radius:0px;
    	width:100%;
    	height:50px;
    	padding:15px 20px;
    	background-color:white;
    	border:1px solid #eaeaea;
    	color:#838383;

    }

    &.typedepropriete:after {
    	content: "\f107";
    	position:absolute;
    	right:20px;
    	top:0;
    	bottom:0;
    	margin:auto 0;
    	display: inline-flex;
    	flex-flow:row wrap;
    	justify-content: center;
    	align-items:center;
	    font: normal normal normal 14px/1 FontAwesome;
	    font-size: inherit;
	    text-rendering: auto;
	    pointer-events: none;
    }
}

.wpcf7-form-control-wrap.your-message {
	width: 96%;
	float: none;
}

.wpcf7-form p {
	//overflow: hidden;
}

.wpcf7-form .c-button--big {
	box-shadow: none;
}

div.wpcf7-validation-errors {
	padding: 0;
	border: none;
	color: #f00;
}

.wpcf7-not-valid-tip {
	text-align: left;
}

.francis-circle-picture {
	width: 175px;
    height: 215px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -80px;

	@media (max-width: 768px) {
		bottom: -100px;
		width: 120px;
    	height: 150px;
	}
}

.contact-content {
	padding-top: 120px;
}
.c-404 {
   padding: 80px 0px;

   @include mq('medium') {
      text-align: center;
   }
}

.c-404__title {

   margin-top: 150px;
   display: block;
   line-height: 1em;

   @include mq('large') {
      margin-top: 45px;
   }

   @include mq('medium') {
      margin-bottom: 70px;
   }

   strong {
      display: block;
      color: $color-grey-scorpion;
   }

   span {
      color: $color-grey-scorpion;
   }


}

.c-404__image {
   width: 620px;
   display: inline-block;
}
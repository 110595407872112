$color-sidebar: #151717;
$color-legal-text: #767676;
$color-input: #e3e3e3;

.c-sidebar {
   width: 340px;
   float: right;
   color: $color-input;

   @include mq(medium) {
      display: none;
   }

   &__wrapper {
        background: $color-sidebar;
        border: 1px solid #d9dfdf;
        padding: 30px;
   }
}

.c-sidebar__title {
   font-family: $font-poppins;
   font-size: 1.0625rem;
   font-weight: 400;
   text-transform: uppercase;
   color: $color-input;
   margin: 0 0 0.5rem 0;
}

.c-sidebar__description {
   font-family: $font-butler;
   font-size: 0.75rem;
   font-weight: 400;
   margin: 0;
   color: $color-grey-scorpion;
   text-transform: uppercase;
   margin-bottom: 30px;
   display: block;
}

.c-sidebar input:last-of-type {
   margin-bottom: 0px;
}

.c-sidebar form {
   margin: 30px 0 20px 0;
}

.c-sidebar__submit-ctn {
   text-align: center;
   padding-bottom: 30px;
   margin-bottom: 30px;
   border-bottom: 1px solid #464646;
}

.c-sidebar__submit {
   display: inline-block;
   background: transparent;
   border: 1px solid $color-input;
   text-transform: uppercase;
   color: $color-input;
   font-family: $font-poppins;
   font-size: 11.5px;
   padding: 10px 15px;
   transition: 0.25s ease all;

   &:hover,
   &:focus {
      color: $color-sidebar;
      background: $color-input;
   }
}

.c-sidebar p.c-sidebar__success {
    font-size: 16px;
    color: $green;
    text-align: center;
    padding: 10px 0px;
}

.c-sidebar p {
   font-family: $font-butler;
   font-size: 0.75rem;
   color: $color-legal-text;
   margin-bottom: 0;
}

.c-sidebar__extend {
   font-family: $font-poppins;
   text-transform: uppercase;
   font-size: 0.75rem;
   color: $color-input;
   background: none;
   border: none;
   margin-top: 25px;
   float: right;
   cursor: pointer;

   &:hover,
   &:focus {
      text-decoration: underline;
   }

   &:after {
      content: "";
      display: inline-block;
      width: 9px;
      height: 5px;
      background: url(../images/new/arrow-down-small.png);
      background-size: 100%;
      margin-left: 10px;
      position: relative;
      top: -2px;
      transition: opacity .25s linear;

      .is-expanded & {
         transform: rotate(180deg);
      }
   }
}

.c-sidebar__extend-ctn {
   max-height: 80px;
   overflow: hidden;
   transition: 0.4s ease all;

   .is-expanded & {
      max-height: 500px;
   }
}

.c-sidebar__group {
   margin-bottom: 1.5em;
}

.c-sidebar__group .has_error {
   border-color: red !important;
}

.c-sidebar__group-label {
   margin-top: 0px;
   margin-bottom: 1em;
   color: $color-grey-lighter;
   font-weight: 700;
}

.c-sidebar select {
   display: block;
   width: 100%;
   margin-bottom: 15px;
   color: $color-grey-light;
   background: white;
}

.c-sidebar select:last-of-type {
   margin-bottom: 0px;
}

.c-sidebar__select-container {
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        transform: rotate(-45deg);
        pointer-events: none;
        z-index: 9999;
        margin: auto 0;

    }
}

.c-sidebar__select-container select {
    appearance: none;
    border-radius: 0;
    padding: 15px 30px 15px 20px;
    margin: 10px 0;
    border: 0;
    font-size: 13px;
}


.cta-sold-listings a {
    width: 100%;
    height: 300px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    background: $color-sidebar;
    padding: 20px;
    margin-top: 30px;

    h3 {
        color: white;
        text-align: center;
        font-family: $font-butler;
        font-weight: 300;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    #c-ventes-icon {
        width: 40px;
    }

    span {
        color: $color-grey-text;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
    }
}

.cta-sold-listings.smaller a {
    height: 250px;

    h3 {
        font-size: 25px;
        line-height: 30px;
    }
}
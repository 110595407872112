.c-confirmation-banner {
    min-height: 500px !important;

    & > div {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        padding-top: 50px;

        h1.section-title {
            margin-top: 30px;
            font-weight: 300;
            text-transform: initial;
            margin-bottom: 0px;
            font-family: $font-butler;
        }

        p {
            font-family: $font-poppins;
            font-size: 16px;
            line-height: 22px;
            color: white;
            font-weight: 300;
            letter-spacing: 1px;
        }
    }

    .confirmation-check-icon {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #3ab13a;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        img {
            width: 20px;
        }
    }
}

.c-confirmation-boxes {
    padding: 100px 0;

    h2 {
        font-family: $font-poppins;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        font-weight: 300;
        margin-bottom: 60px;
    }

    &-wrapper {
        display: flex;
        flex-flow: row wrap;
    }

    .c-list-acheteurs__single {
        float: none;
    }
}